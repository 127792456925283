import config from '../Config.js';

/**
 * 
 * @returns 
 */
export function getDashboard() {
    return fetch(config.hostApi + 'dashboard', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer  ' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesDeliveredByDay(data) {
    return fetch(config.hostApi + 'dashboard/delivered', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesPlannedByDay(data) {
    return fetch(config.hostApi + 'dashboard/planned', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesFailedByDay(data) {
    return fetch(config.hostApi + 'dashboard/failed', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * @returns 
 */
export function coursesStatusByDay() {
    return fetch(config.hostApi + 'dashboard/status', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function coursesMerchantPrice(data) {
    return fetch(config.hostApi + 'dashboard/merchantprice', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}
