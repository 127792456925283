import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Divider, Layout, Card, Col, Row, DatePicker, Button } from 'antd';
import { RightCircleTwoTone, ShoppingCartOutlined, TeamOutlined, LineChartOutlined } from '@ant-design/icons';

import { XAxis, YAxis, Tooltip, CartesianGrid, PieChart, Pie, Sector, Cell, ResponsiveContainer, LineChart, Line } from 'recharts';

import { getDashboard, coursesDeliveredByDay, coursesFailedByDay, coursesStatusByDay, coursesPlannedByDay, coursesMerchantPrice } from '../../Api/Dashboard.js';

/**
 * 
 */
class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {moment(payload.value, 'YYYYMMDD').format('DD/MM/YY')}
                </text>
            </g>
        );
    }
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const CustomTooltipDelivered = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-title">{`${moment(payload[0].payload.deliveryDate, 'YYYYMMDD').format('DD/MM/YY')} `}</p>
                <p className="custom-tooltip-label">{`Courses délivrées : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const CustomTooltipFailed = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-title">{`${moment(payload[0].payload.deliveryDate, 'YYYYMMDD').format('DD/MM/YY')} `}</p>
                <p className="custom-tooltip-label">{`Courses en échec : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const CustomTooltipPlanned = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-title">{`${moment(payload[0].payload.deliveryDate, 'YYYYMMDD').format('DD/MM/YY')} `}</p>
                <p className="custom-tooltip-label">{`Courses prévues : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const CustomTooltipMerchantPrice = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-title">{`${moment(payload[0].payload.deliveryDate, 'YYYYMMDD').format('DD/MM/YY')} `}</p>
                <p className="custom-tooltip-label" style={{ color: "#2FAC66" }}>{`Prix des courses réussies : ${payload[0].payload.success} €`}</p>
                <p className="custom-tooltip-label" style={{ color: "#0B4624" }}>{`Prix des courses en échec : ${payload[1].payload.error} €`} </p>
            </div>
        );
    }

    return null;
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    let translateStatut = null;
    if (payload.status === 'canceled') {
        translateStatut = "Annulée"
    } else if (payload.status === 'available') {
        translateStatut = "Emise"
    } else if (payload.status === 'assigned') {
        translateStatut = "Assignée"
    } else if (payload.status === 'started') {
        translateStatut = "Démarée"
    } else if (payload.status === 'inProgress') {
        translateStatut = "Colis Récupéré"
    } else if (payload.status === 'isDelivered') {
        translateStatut = "Livrée"
    } else if (payload.status === 'draft') {
        translateStatut = "Brouillon"
    } else if (payload.status === 'failed') {
        translateStatut = "Echouée"
    } else {
        translateStatut = "Impossible"
    }

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {translateStatut}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${translateStatut} : ${payload.total_status}`}</text>
        </g>
    );
};

class Dashboard extends React.Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            dashboard: null,

            coursesDelivered: [],
            coursesFailed: [],
            coursesStatus: [],
            coursesPlanned: [],
            coursesMerchantPrice: [],
            dateEnd: moment().format('YYYY-MM-DD'),
            dateStart: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            activeIndex: 0,
            heightGraph: 500
        }

        this.mapContainer = React.createRef();
        this.onDateChange = this.onDateChange.bind(this);
        this.onDateSend = this.onDateSend.bind(this);
    }

    /**
     * 
     */
    async loadDashboard() {
        await getDashboard()
            .then((res) => res.json())
            .then((data) =>
                this.setState({ dashboard: data })
            )
    }


    /**
     * 
     */
    async loadCoursesDelivered() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        }
        coursesDeliveredByDay(data)
            .then((res) => res.json())
            .then((data) => {
                this.setState({ coursesDelivered: data })
            })
    }

    /**
     * 
     */
    async loadCoursesPlanned() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        }
        coursesPlannedByDay(data)
            .then((res) => res.json())
            .then((data) => {
                this.setState({ coursesPlanned: data })
            })
    }

    /**
     * 
     */
    async loadCoursesFailed() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        }
        coursesFailedByDay(data)
            .then((res) => res.json())
            .then((data) => {
                this.setState({ coursesFailed: data })
            })
    }

    /**
     * 
     */
    async loadCoursesStatus() {
        coursesStatusByDay()
            .then((res) => res.json())
            .then((data) => {
                this.setState({ coursesStatus: data })
            })
    }

    /**
     * 
     */
    async loadCoursesMerchantPrice() {
        let data = {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd
        }
        coursesMerchantPrice(data)
            .then((res) => res.json())
            .then((data) => {
                this.setState({ coursesMerchantPrice: data })
            })
    }

    /**
     * 
     */
    async loadData() {
        await this.loadDashboard();
        await this.loadCoursesDelivered();
        await this.loadCoursesPlanned();
        await this.loadCoursesFailed();
        await this.loadCoursesStatus();
        await this.loadCoursesMerchantPrice();
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadData();

        if (window.innerWidth < 768) {
            this.setState({ heightGraph: 300 })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    onDateChange(values) {
        if (values !== null) {
            this.setState({
                dateStart: values[0].format('YYYY-MM-DD'),
                dateEnd: values[1].format('YYYY-MM-DD')
            })
        } else {
            this.setState({
                dateStart: null,
                dateEnd: null
            })
        }
    }

    /**
     * 
     */
    onDateSend() {
        this.loadCoursesDelivered();
        this.loadCoursesPlanned();
        this.loadCoursesFailed();
        this.loadCoursesMerchantPrice();
    }

    /**
     * 
     * @param {*} _ 
     * @param {*} index 
     */
    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const { Content } = Layout;
        const { RangePicker } = DatePicker;
        const dateFormat = 'DD/MM/YYYY';
        const COLORS = ['#2FAC66', '#268E50', '#0B4624', '#75D89D', '#23DB73', '#75B567', '#3BAE64', '#ABFBCE'];

        let nbCourses = null;
        if (this.state.dashboard !== null) {
            nbCourses = this.state.dashboard.coursesCount;
        }

        let nbCustomers = null;
        if (this.state.dashboard !== null) {
            nbCustomers = this.state.dashboard.customersCount;
        }

        return (
            <Content>
                <div style={{ margin: '16px 16px' }} className="site-card-wrapper" >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={8}>
                            <Card size="small" bordered={false} style={{ textAlign: 'right', fontSize: '16px', backgroundColor: '#DDEBDF', overflow: 'hidden' }}>
                                <div className="visual"><ShoppingCartOutlined className="visual-icon flag" /></div>
                                <div className="details">
                                    <span style={{ fontSize: '34px' }}>{nbCourses}</span>
                                    <br />
                                    Nombre de Courses
                                </div>
                                <Divider style={{ margin: '10px 0' }} ></Divider>
                                <Row gutter={24} style={{ fontSize: '14px' }} >
                                    <Col span={20} style={{ textAlign: 'left' }}>
                                        <Link to='/courses' >Voir tout</Link>
                                    </Col>
                                    <Col span={2}>
                                        <RightCircleTwoTone twoToneColor="#2fac66" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card size="small" bordered={false} style={{ textAlign: 'right', fontSize: '16px', backgroundColor: '#DDEBDF', overflow: 'hidden' }}>
                                <div className="visual"><TeamOutlined className="visual-icon" /></div>
                                <div className="details">
                                    <span style={{ fontSize: '34px' }}>{nbCustomers}</span>
                                    <br />
                                    Nombre de Clients
                                </div>
                                <Divider style={{ margin: '10px 0' }} ></Divider>
                                <Row gutter={24} style={{ fontSize: '14px' }}>
                                    <Col span={20} style={{ textAlign: 'left', }}>
                                        <Link to='/customers'>Voir tout</Link>
                                    </Col>
                                    <Col span={2}>
                                        <RightCircleTwoTone twoToneColor="#2fac66" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Content className="site-layout-background"  style={{ backgroundColor: "white", margin: '16px 16px' }}>
                    <h2 style={{ color: "#2FAC66" }}><LineChartOutlined /> STATISTIQUES</h2>
                    <Divider></Divider>
                    <Row gutter={[24, 16]}>
                        <Col xs={24} lg={10}>
                            <div >Sélectionnez une période pour obtenir vos statistiques :</div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <RangePicker defaultValue={[moment(), moment().subtract(7, 'days')]} format={dateFormat} onChange={this.onDateChange} />
                        </Col>
                        <Col xs={24} lg={4}>
                            <Button type="primary" onClick={this.onDateSend}>
                                Envoyer
                            </Button>
                        </Col>

                    </Row>
                    <Divider></Divider>
                    <div style={{ marginBottom: '25px' }}>
                        <Row gutter={[24, 32]}>
                            <Col xs={24} lg={12}>
                                <h3>Nombre de course délivrées par jour</h3>
                                <ResponsiveContainer width="100%" height={this.state.heightGraph}>
                                    <LineChart
                                        data={this.state.coursesDelivered}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="deliveryDate" minTickGap={2} tick={<CustomizedAxisTick />} height={60} />
                                        <YAxis />
                                        <Tooltip content={<CustomTooltipDelivered />} />
                                        <Line type="monotone" dataKey="total_delivered" stroke="#2FAC66" />
                                    </LineChart>
                                </ResponsiveContainer>

                            </Col>
                            <Col xs={24} lg={12}>
                                <h3>Nombre de course en échec par jour</h3>
                                <ResponsiveContainer width="100%" height={this.state.heightGraph}>
                                    <LineChart
                                        data={this.state.coursesFailed}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="deliveryDate" minTickGap={2} tick={<CustomizedAxisTick />} height={60} />
                                        <YAxis />
                                        <Tooltip content={<CustomTooltipFailed />} />
                                        <Line type="monotone" dataKey="total_failed" stroke="#2FAC66" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                        <Row gutter={[24, 32]}>
                            <Col xs={24} lg={12}>
                                <h3>Nombre de course plannifiées par jour</h3>
                                <ResponsiveContainer width="100%" height={this.state.heightGraph}>
                                    <LineChart
                                        data={this.state.coursesPlanned}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="deliveryDate" minTickGap={2} tick={<CustomizedAxisTick />} height={60} />
                                        <YAxis />
                                        <Tooltip content={<CustomTooltipPlanned />} />
                                        <Line type="monotone" dataKey="total_planned" stroke="#2FAC66" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col xs={24} lg={12}>
                                <h3>Prix par jour</h3>
                                <ResponsiveContainer width="100%" height={this.state.heightGraph}>
                                    <LineChart
                                        data={this.state.coursesMerchantPrice}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="deliveryDate" minTickGap={2} tick={<CustomizedAxisTick />} height={60} />
                                        <YAxis />
                                        <Tooltip content={<CustomTooltipMerchantPrice />} />
                                        <Line type="monotone" dataKey="success" stroke="#2FAC66" />
                                        <Line type="monotone" dataKey="error" stroke="#0B4624" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                        <Row gutter={[24, 32]}>
                            <Col span={24}>
                                <h3>Statuts des courses du jour</h3>
                                <ResponsiveContainer width="100%" height={this.state.heightGraph}>
                                    <PieChart >
                                        <Pie
                                            data={this.state.coursesStatus}
                                            activeIndex={this.state.activeIndex}
                                            activeShape={renderActiveShape}
                                            dataKey="total_status"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={80}
                                            outerRadius={140}
                                            fill="#2FAC66"
                                            onMouseEnter={this.onPieEnter}
                                        >
                                            {
                                                this.state.coursesStatus.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                            }
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Content>
        );
    }
}

export default Dashboard;