import { createContext, useContext } from 'react';
import loginFetch, { refresh as refreshTokenApi } from './Api/Login';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import 'moment/locale/fr';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth()  {
  let timeOutRefresh = null;

  const signin = (payload, resolved, rejected) => {
    clearTimeout(timeOutRefresh);

    return loginFetch(payload)
      .then((response) => {
        if (!response.ok) throw Error();
        return response.json();
      })
      .then(async (data) => {
        await authenticate(data.token);
        resolved();
      })
      .catch(() => {
        rejected();
      });
  };

  const signout = (cb) => {
    clearTimeout(timeOutRefresh);
    localStorage.removeItem('token');

    cb();
  };

  const authenticate = (token) => {
    return new Promise((resolve, reject) => {
      localStorage.setItem("token", token);

      let decode_token = jwt_decode(token);
      let exp = moment.unix(decode_token['exp']);
      // 10% du delta entre now et l'exp
      let deltaMiliSec = ((exp.unix() - moment().unix()) * 1000) * 0.9;

      refreshAccessToken(deltaMiliSec)
        .catch(() => {
          signout(() => {});
        });

      resolve();
    });
  };

  const isExpired = () => {
    const token = localStorage.getItem("token");

    try {
        const decode_token = jwt_decode(token);
        let delta = decode_token['exp'] - decode_token["iat"];
        let exp = moment.unix(decode_token["iat"] + delta);

        if (exp > moment() ) {
            return false;
        }
    } catch (err) { }

    return true;
  };

  const refreshAccessToken = (delta = 3000) => {
    clearTimeout(timeOutRefresh);

    return new Promise((resolve, reject) => {
      timeOutRefresh = setTimeout(() => {
        refreshTokenApi()
          .then((response) => {
            if (!response.ok) throw Error();
            return response.json();
          })
          .then(async (data) => {
            await authenticate(data.token);
            resolve(data);
          })
          .catch(() => {
            signout();
          });
      }, delta);
    });
  };

  return {
    signin,
    signout,
    isExpired,
    refreshAccessToken,
  };
}
