import React from 'react';

import { Row, Col, Card, Divider } from 'antd';
import { HomeOutlined, LockOutlined, EuroOutlined, UserOutlined } from '@ant-design/icons';

import { GlobalContext } from '../../GlobalContext.js';

class Profile extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
    }

    render() {
        let user = this.context.global.profile;
        let merchant = null;
        let informations = null;
        if (user !== null) {
            merchant = user.merchant;
            informations = (
                <div>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Card
                                className="profile-card"
                                title={(
                                    <>
                                        <HomeOutlined /> Informations Boutique
                                    </>
                                )}
                                style={{ marginBottom: '20px' }}
                            >
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Nom de la boutique :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10} >{merchant.socialName} </Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Adresse :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.address}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Téléphone de la boutique:</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.phone}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Téléphone du commerçant :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.ownerPhone}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Nom du contact :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.ownerLastName} {merchant.ownerFirstName}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">ID du commerçant :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.id} </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Card
                                className="profile-card"
                                title={(
                                    <>
                                        <LockOutlined /> Informations de connexion
                                    </>
                                )}
                                style={{ marginBottom: '20px' }}
                            >
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={12} lg={12} className="profil-title">Mail Boutique :</Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>{merchant.ownerEmail}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={12} lg={12} className="profil-title">Mot de passe:</Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>***************</Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Card
                                className="profile-card"
                                title={(
                                    <><EuroOutlined /> Informations de facturation
                                    </>
                                )}
                                style={{ marginTop: '20px' }}
                            >
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Nom de facturation :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.name} </Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Adresse de facturation :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.address}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">Statut juridique:</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.legalForm}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">N° de TVA :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.tva}</Col>
                                </Row>
                                <Row gutter={[12, 0]}>
                                    <Col xs={24} sm={24} md={14} lg={14} className="profil-title">N° de SIRET :</Col>
                                    <Col xs={24} sm={24} md={10} lg={10}>{merchant.siret}</Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        }
        return (
            <div style={{ margin: '16px 16px' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <h2 style={{ color: "#2FAC66" }}><UserOutlined /> PROFIL</h2>
                    <Divider></Divider>
                    {informations}
                </div>
            </div>
        )
    }
}

export default Profile;
