import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.less';
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import MapMerchant from './Pages/Map/MapMerchant';
import Courses from './Pages/Courses/Courses';
import CourseAdd from './Pages/Courses/CourseAdd';
import AppLayout from './AppLayout';
import Profile from './Pages/Profile/Profile';
import Customers from './Pages/Customers/Customers';
import Stores from './Pages/Stores/Stores';
import { GlobalContext } from './GlobalContext';
import { ProvideAuth, useAuth } from './auth';
import { getUser } from "./Api/Login";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({component: Component, ...rest}) {
    const auth = useAuth();
    const token = localStorage.getItem("token");
    const hasToken = !!token && (token !== "undefined");
    const [isAuthenticated, setIsAuthenticated] = useState(hasToken);
    const [loading, setLoading] = useState(false);

    // Requis pour le context global
    const [global, updateGlobal] = useState({
        'profile': null,
        'config': null
    });

    /**
    *
    */
    const refreshToken = () => {
        auth.refreshAccessToken()
            .then(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            })
            .catch(() => {
                auth.signout(() => {});
                setTimeout(() => {
                    setLoading(false);
                    setIsAuthenticated(false);
                }, 2000);
            });
    };

    /**
    *
    */
    const loadUser = () => {
        getUser()
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                if (!data.user) {
                    throw new Error();
                }

                updateGlobal({
                    ...global,
                    profile: data.user
                });
            })
            .catch(() => {
                setIsAuthenticated(false);
            });
    };

    if (isAuthenticated && !loading && auth.isExpired()) {
        setLoading(true);
        refreshToken();
    } else if (global.profile === null && !loading) {
        setLoading(true);
        loadUser();
    } else if (global.profile !== null && loading) {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                let state = {
                    'global': global,
                    'updateGlobal': updateGlobal
                }

                return (loading) ? (
                    "chargement"
                ) : (isAuthenticated) ? (
                    <GlobalContext.Provider value={state}>
                        <AppLayout>
                            <Component {...props} />
                        </AppLayout>
                    </GlobalContext.Provider>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location},
                        }}
                    />
                );
            }}
        />
    );
}

function App() {
    return (
        <ProvideAuth>
            <Router>
                <div className="App">
                    <Switch>
                        <Route exact path='/login' component={Login}/>
                        <PrivateRoute exact path='/' component={Dashboard}/>
                        <PrivateRoute exact path='/map' component={MapMerchant}/>
                        <PrivateRoute exact path='/courses' component={Courses}/>
                        <PrivateRoute exact path='/course/add' component={CourseAdd}/>
                        <PrivateRoute exact path='/profile' component={Profile}/>
                        <PrivateRoute exact path='/customers' component={Customers}/>
                        <PrivateRoute exact path='/stores' component={Stores}/>
                    </Switch>
                </div>
            </Router>
        </ProvideAuth>
    );
}

export default App;
