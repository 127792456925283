import React from 'react';

import { Button, Space, Table, Divider, notification, Popconfirm } from 'antd';
import { EyeOutlined, DeleteOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { GlobalContext } from '../../GlobalContext.js';
import { getMerchantStores, deleteStore } from '../../Api/Stores';

import StoreDrawer from '../../Components/StoreDrawer.js';


class Stores extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            loading: 1,
            visibleStoreDrawer: false,
            stores: [],
            id: null,
            actionStore: 'save'
        }

        this.columns = [
            {
                title: 'Nom',
                dataIndex: 'store_name',
                key: 'store_name',
            },
            {
                title: 'Adresse',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <div>
                        <Space wrap>
                            <Button size='small' type='primary' onClick={(e) => this.onClickEditStore(id, e)}>
                                <EyeOutlined />
                            </Button>
                            <Popconfirm
                                title="Etes-vous sûr de vouloir supprimer cet entrepôt ?"
                                onConfirm={(e) => this.onClickDeleteStore(id, e)}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button size='small' type='primary' danger className='button-table'>
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        </Space>
                    </div>
                )
            },
        ];


        this.showStoreDrawer = this.showStoreDrawer.bind(this);
        this.showDrawer = this.showDrawer.bind(this);
        this.onClickEditStore = this.onClickEditStore.bind(this);
        this.onClickDeleteStore = this.onClickDeleteStore.bind(this);
    }

    /**
     * 
     */
    async loadStores() {

        await getMerchantStores()
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    stores: data.merchantStores
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadStores();
    }

    /**
     * 
     */
    showStoreDrawer = () => {
        this.setState({ visibleStoreDrawer: true });
    }

    /**
     *
     * @param {*} value
     */
    showDrawer = (value) => {
        this.loadStores();
        this.setState({
            visibleStoreDrawer: value,
            actionStore: 'save'
        });
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    onClickDeleteStore = (id, e) => {
        e.preventDefault();
        deleteStore(id)
            .then((res) => res.json())
            .then(() => {
                notification['success']({
                    message: 'L\'entrepot a bien été supprimé'
                })
                this.loadStores();
            })
    }

    /**
     * 
     * @param {*} id 
     */
    onClickEditStore = (id) => {
        this.setState({
            id: id,
            visibleStoreDrawer: true,
            actionStore: 'update'
        });
    }

    render() {
        return (
            <div>
                <div style={{ margin: '16px 16px' }}>
                    <div className="site-layout-background" style={{ minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined />  LISTE DES ENTREPOTS</h2>
                        <Divider></Divider>
                        <Button style={{ marginBottom: '20px' }} type='primary' onClick={this.showStoreDrawer}>Ajouter un entrepôt</Button>
                        <Table
                            columns={this.columns}
                            loading={this.state.loading}
                            dataSource={this.state.stores}
                        />
                    </div>
                </div>
                <StoreDrawer id={this.state.id} showStoreDrawer={this.state.visibleStoreDrawer} showDrawer={this.showDrawer} action={this.state.actionStore} />
            </div>
        )
    }
}

export default Stores;