import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import moment from 'moment';

import {
    Divider,
    Space,
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    TimePicker,
    Checkbox,
    Row,
    Col,
    Drawer,
    Radio,
    message,
    Grid,
} from 'antd';
import {
    PlusCircleOutlined,
    DropboxOutlined,
    EditOutlined,
    CaretRightOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import CustomerForm from '../../Components/CustomerForm.js';
import PacketForm from "../../Components/PacketForm";

import { getDistance, getOpeningSchedules, getTransport } from '../../Api/Courses';
import { getCustomers, getOneCustomer } from '../../Api/Customers';
import { getStores } from '../../Api/Stores';
import { getCourseDeliveryOptions } from '../../Api/DeliveryOptions';
import { getOneMerchantTheme } from '../../Api/MerchantThemes';
import { getOneNetwork } from '../../Api/Networks';

const { Option } = Select;
const { useBreakpoint } = Grid;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const timeFormat = 'HH:mm';

const themeSelect = (theme) => ({
    ...theme,
    borderRadius: 2,
    borderWidth: '1px !important',
    colors: {
        ...theme.colors,
        primary: '#2FAC66', // active borderColor
        neutral5: '#F5F5F5', // disabled background
        neutral10: '#D9D9D9', // disabled border
        neutral20: '#D9D9D9', // disabled arrow
        neutral40: '#BFBFBF', // disabled placeholder
        neutral50: '#BFBFBF', // disabled value
    }
});

/**
 *
 * @param {*} props
 * @returns
 */
const FormCourse = (props) => {
    const [form] = Form.useForm();
    const [formDeliveryOptions] = Form.useForm();

    const screens = useBreakpoint();

    const { user } = props;

    const [merchantId, setMerchantId] = React.useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = React.useState(null);
    const [dataCustomer, setDataCustomer] = React.useState([]);
    const [pickupArea, setPickupArea] = React.useState(null);
    const [deliveryArea, setDeliveryArea] = React.useState(null);
    const [showCustomerForm, setShowCustomerForm] = React.useState(false);
    const [visibleDrawerPacket, setVisibleDrawerPacket] = React.useState(false);
    const [visibleDrawerDeliveryOptions, setVisibleDrawerDeliveryOptions] = React.useState(false);
    const [packetInformations, setPacketInformations] = React.useState(null);
    const [customerInformations, setCustomerInformations] = React.useState(null);
    const [valuePickupArea, setValuePickupArea] = React.useState("merchant");
    const [actionCustomer, setActionCustomer] = React.useState('save');
    const [merchantStores, setMerchantStores] = React.useState([]);
    const [pickupLatitude, setPickupLatitude] = React.useState(null);
    const [pickupLongitude, setPickupLongitude] = React.useState(null);
    const [direction, setDirection] = React.useState("");
    const [distance, setDistance] = React.useState(null);
    const [openingSchedules, setOpeningSchedules] = React.useState([]);
    const [networkId, setNetworkId] = React.useState(null);
    const [merchantThemeId, setMerchantThemeId] = React.useState(null);
    const [commissionNetwork, setCommissionNetwork] = React.useState(null);
    const [commissionMerchantTheme, setCommissionMerchantTheme] = React.useState(null);
    const [dataNetworkDeliveryOptions, setDataNetworkDeliveryOptions] = React.useState([]);
    const [dataMerchantDeliveryOptions, setDataMerchantDeliveryOptions] = React.useState([]);
    const [dataMerchantThemeDeliveryOptions, setDataMerchantThemeDeliveryOptions] = React.useState([]);
    const [selectedDeliveryOptions, setSelectedDeliveryOptions] = React.useState([]);
    const [commission, setCommission] = React.useState(null);

    const disablePickupArea = (valuePickupArea !== "custom");
    const disablePickupAreaAdress = (valuePickupArea === "merchant" || valuePickupArea === "store");
    const disableEditCustomerButton = (!selectedCustomerId);
    const hiddenSelectStores = (valuePickupArea !== "store");
    const disableDeliveryAreaAdress = true;

    let selectDeliveryArea = null;
    let selectPickupArea = null;

    let networkDeliveryOptions = null;
    let merchantDeliveryOptions = null;
    let merchantThemeDeliveryOptions = null;

    /**
     *
     * @param {*} current
     * @returns
     */
    function disabledDate(current) {
        // Can not select days before today 
        return current && current < moment().startOf('day');
    }

    /**
     *
     * @param {*} id
     */
    const fetchCommissionNetwork = (id) => {
        getOneNetwork(id)
            .then(res => res.json())
            .then(json => {
                if (json.network.commission !== null) {
                    setCommissionNetwork(json.network.commission.commission_price)
                }
            });
    };

    /**
     *
     * @param {*} id
     */
    const fetchCommissionMerchantTheme = (id) => {
        getOneMerchantTheme(id)
            .then(res => res.json())
            .then(json => {
                if (json.merchanttheme.commission !== null) {
                    setCommissionMerchantTheme(json.merchanttheme.commission.commission_price)
                }
            });
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = async (values) => {
        if (values.collect_time === undefined || values.collect_time === null) {
            message.error('Horaire de retrait manquant');
        } else if (values.delivery_date === undefined || values.delivery_date === null) {
            message.error('Date de livraison manquante');
        } else if (values.delivery_time === undefined || values.delivery_time === null) {
            message.error('Horaire de livraison manquant');
        } else if (merchantId !== null && (valuePickupArea === 'custom_merchant' || valuePickupArea === 'custom') && pickupArea === null) {
            message.error('Merci de sélectionner une adresse de retrait');
        } else if (values.customer_id === undefined) {
            message.error('Merci de sélectionner un client');
        } else if (packetInformations === null ||
            (packetInformations.packet_weight === null || packetInformations.packet_length === null || packetInformations.packet_width === null || packetInformations.packet_height === null)
        ) {
            message.error('Merci de renseigner le poids et la taille dans les informations du colis.');
        } else if (values.customer_id === null) {
            message.error('Merci de sélectionner un client.');
        } else {
            let newCourse = {};
            let dataForTransports = {
                distance: distance,
                weight: null,
                volume: null
            };

            newCourse.collectTime = values['collect_time'].format('HH:mm');
            newCourse.collectDate = values['delivery_date'].format('DD/MM/YYYY');
            newCourse.deliveryTime = values['delivery_time'].format('HH:mm');
            newCourse.customerId = values.customer_id;
            newCourse.distance = distance;

            if (packetInformations) {
                newCourse.packet_description = packetInformations.packet_description;
                newCourse.packet_comment = packetInformations.packet_comment;
                newCourse.packet_price = packetInformations.packet_price;
                newCourse.packet_weight = packetInformations.packet_weight;
                newCourse.packet_length = packetInformations.packet_length;
                newCourse.packet_width = packetInformations.packet_width;
                newCourse.packet_height = packetInformations.packet_height;
                newCourse.packet_volume = packetInformations.packet_volume;

                dataForTransports.weight = packetInformations.packet_weight;
                dataForTransports.volume = packetInformations.packet_volume;
            }

            if (customerInformations !== null) {
                newCourse.customerAddress = customerInformations.address;
                newCourse.customerFirstName = customerInformations.firstname;
                newCourse.customerLastName = customerInformations.name;
                newCourse.customerMail = customerInformations.email;
                newCourse.customerStage = customerInformations.floor;
                newCourse.customerPhone = customerInformations.phone;
                newCourse.customerMobilePhone = customerInformations.mobilePhone;
                newCourse.customerInterphoneCode = customerInformations.intercomCode;
                newCourse.customerComment = customerInformations.comment;
                newCourse.customerZipCode = customerInformations.zip_code;
                newCourse.customerLocation = [customerInformations.latitude, customerInformations.longitude];
            }

            if (selectedDeliveryOptions.length === 0) {
                newCourse.delivery_options = [];
            } else {
                newCourse.delivery_options = selectedDeliveryOptions.delivery_options;
            }

            if (valuePickupArea === "custom_merchant") {
                newCourse.customStartAddress = pickupArea.label;
                newCourse.customStartZipCode = values.pickupAreaZipCode;
                newCourse.customStartLocation = [pickupLatitude, pickupLongitude];
            } else if (valuePickupArea === 'custom') {
                newCourse.pickupAreaName = values.pickupAreaName;
                newCourse.pickupAreaAddress = pickupArea.label;
                newCourse.pickupAreaContactFirstname = values.pickupAreaContactFirstname;
                newCourse.pickupAreaContactLastname = values.pickupAreaContactLastname;
                newCourse.pickupAreaEmail = values.pickupAreaEmail;
                newCourse.pickupAreaMobilePhone = values.pickupAreaMobilePhone;
                newCourse.pickupAreaPhone = values.pickupAreaPhone;
                newCourse.pickupAreaLocation = [pickupLatitude, pickupLongitude];
                newCourse.pickupAreaZipCode = values.pickupAreaZipCode;
            } else if (valuePickupArea === 'store') {
                newCourse.store_id = values.store_id;
                newCourse.storeAreaName = values.pickupAreaName;
                newCourse.storeAreaAddress = pickupArea.label;
                newCourse.storeAreaContactFirstname = values.pickupAreaContactFirstname;
                newCourse.storeAreaContactLastname = values.pickupAreaContactLastname;
                newCourse.storeAreaEmail = values.pickupAreaEmail;
                newCourse.storeAreaMobilePhone = values.pickupAreaMobilePhone;
                newCourse.storeAreaPhone = values.pickupAreaPhone;
                newCourse.storeAreaLat = pickupLatitude;
                newCourse.storeAreaLng = pickupLongitude;
                newCourse.storeAreaZipCode = values.pickupAreaZipCode;
            }

            getTransport(dataForTransports)
                .then(res => res.json())
                .then(json => {
                    newCourse.transports = json.transport;
                    props.formSubmit(newCourse);
                });
        }
    };

    /**
     *
     */
    const fetchOpeningSchedules = () => {
        getOpeningSchedules()
            .then(res => res.json())
            .then(json => {
                setOpeningSchedules(json.openingSchedules);
            })
            .catch(() => {});
    };

    /**
     *
     * @param {*} pickupArea
     */
    const onChangePickupAreaLocation = (pickupArea) => {
        setPickupArea({
            "label": pickupArea.label,
            "value": {}
        });

        Promise.all([
            geocodeByAddress(pickupArea.label).then(results => getLatLng(results[0])),
            geocodeByAddress(pickupArea.label)
                .then(results => {
                    let zipCode = "";
                    let addressResults = results[0].address_components;
                    addressResults.forEach(addressResult => {
                        let types = addressResult.types;
                        types.forEach(type => {
                            if (type === "postal_code") {
                                zipCode = addressResult.long_name;
                            }
                        });
                    });

                    form.setFieldsValue({
                        pickupAreaAddress: pickupArea.label,
                        pickupAreaZipCode: zipCode
                    });
                })
        ]).then(allResponse => {
            let lat = allResponse[0].lat;
            let lng = allResponse[0].lng;
            setPickupLatitude(lat);
            setPickupLongitude(lng);
        });
    };

    /**
     *
     * @param {*} deliveryArea
     */
    const onChangeDeliveryAreaLocation = (deliveryArea) => {
        geocodeByAddress(deliveryArea.label)
            .then(results => getLatLng(results[0]));

        setDeliveryArea({
            "label": deliveryArea.label,
            "value": {}
        });
    };

    /**
     *
     * @param {*} value
     */
    const handleSearchCustomer = (value) => {
        if (value.length < 3) {
            return;
        }

        let payload = { term: value };
        getCustomers(payload)
            .then((res) => res.json())
            .then((data) => {
                setDataCustomer(data.customer);
            });
    };

    /**
     *
     * @param {*} value
     */
    const handleChangeCustomer = async (value) => {
        if (!value) {
            setSelectedCustomerId(null);
            setDistance(null);
            return;
        }

        await getOneCustomer(value)
            .then((res) => res.json())
            .then((data) => {
                setCustomer(data.customer);
                setAddressDistance(data.customer.address);
            });
    };

    /**
     *
     */
    const onShowCustomerForm = () => {
        setActionCustomer('save');
        setShowCustomerForm(true);
    };

    /**
     *
     */
    const onClickEditCustomer = () => {
        setActionCustomer('update');
        setShowCustomerForm(true);
    };

    /**
     *
     * @param {*} customerId
     */
    const onCloseCustomerForm = (customerId) => {
        setShowCustomerForm(false);

        if (!customerId) {
            return;
        }

        getOneCustomer(customerId)
            .then((res) => res.json())
            .then((data) => {
                setCustomer(data.customer);
                setAddressDistance(data.customer.address);
            });
    };

    /**
     *
     * @param {*} customer
     */
    const setCustomer = (customer) => {
        setSelectedCustomerId(customer.id);
        setCustomerInformations(customer);
        setDataCustomer([customer]);

        form.setFieldsValue({
            customer_id: customer.id,
            deliveryAreaFirstname: customer.firstname,
            deliveryAreaLastname: customer.name,
            deliveryAreaAddress: customer.address,
            deliveryAreaPhone: customer.phone,
            deliveryAreaMobilePhone: customer.mobilePhone,
            deliveryAreaZipCode: customer.zip_code
        });

        setDeliveryArea({
            "label": customer.address,
            "value": {}
        });
    };

    /**
     *
     * @param {*} address
     */
    const setAddressDistance = (address) => {
        if (!pickupArea) {
            return;
        }

        getDistance(pickupArea.label, address)
            .then((res) => res.json())
            .then((data) => {
                setDistance(data.distance);
            })
            .catch(() => {});
    };

    /**
     *
     */
    const showDrawerPacket = () => {
        setVisibleDrawerPacket(true);
    };

    /**
     *
     */
    const onClosePacket = () => {
        setVisibleDrawerPacket(false);
    };

    /**
     *
     * @param {*} values
     */
    const onSubmitPacket = (values) => {
        setPacketInformations(values);
        setVisibleDrawerPacket(false);
    };

    /**
     *
     * @param {*} e
     */
    const onChangePickupArea = e => {
        setValuePickupArea(e.target.value);
        if (e.target.value === "custom_merchant") {
            if (user !== null && user.merchant !== null) {
                form.setFieldsValue({
                    pickupAreaName: user.merchant.name,
                    pickupAreaContactFirstname: user.merchant.ownerFirstName,
                    pickupAreaContactLastname: user.merchant.ownerLastName,
                    pickupAreaPhone: user.merchant.phone,
                    pickupAreaMobilePhone: user.merchant.ownerPhone,
                    pickupAreaEmail: user.merchant.ownerEmail,
                    pickupAreaAddress: null,
                    pickupAreaZipCode: null
                });

                setPickupArea(null);
            }
        }

        if (e.target.value === 'custom') {
            form.setFieldsValue({
                pickupAreaName: null,
                pickupAreaContactFirstname: null,
                pickupAreaContactLastname: null,
                pickupAreaPhone: null,
                pickupAreaMobilePhone: null,
                pickupAreaEmail: null,
                pickupAreaAddress: null,
                pickupAreaZipCode: null
            });

            setPickupArea(null);
        }

        if (e.target.value === 'merchant') {
            if (user !== null && user.merchant !== null) {
                form.setFieldsValue({
                    pickupAreaName: user.merchant.name,
                    pickupAreaContactFirstname: user.merchant.ownerFirstName,
                    pickupAreaContactLastname: user.merchant.ownerLastName,
                    pickupAreaPhone: user.merchant.phone,
                    pickupAreaMobilePhone: user.merchant.ownerPhone,
                    pickupAreaEmail: user.merchant.ownerEmail,
                    pickupAreaAddress: user.merchant.address,
                    pickupAreaZipCode: user.merchant.zip_code
                });

                setPickupArea({
                    "label": user.merchant.address,
                    "value": {}
                });

                setPickupLatitude(user.merchant.latitude);
                setPickupLongitude(user.merchant.longitude);
            }
        }

        if (e.target.value === 'store') {
            getStores()
                .then((res) => res.json())
                .then(json => {
                    setMerchantStores(json.merchantStores);
                });

            form.setFieldsValue({
                pickupAreaName: null,
                pickupAreaContactFirstname: null,
                pickupAreaContactLastname: null,
                pickupAreaPhone: null,
                pickupAreaMobilePhone: null,
                pickupAreaEmail: null,
                pickupAreaAddress: null,
                pickupAreaZipCode: null
            });

            setPickupLatitude(null);
            setPickupLongitude(null);

            setPickupArea(null);
        }
    };

    /**
     *
     * @param {*} value
     */
    const handleChangeSelectStore = (value) => {
        const selectedStore = merchantStores.find(d => d.id === value);

        form.setFieldsValue({
            pickupAreaName: selectedStore.store_name,
            pickupAreaContactFirstname: selectedStore.contact_firstname,
            pickupAreaContactLastname: selectedStore.contact_name,
            pickupAreaPhone: selectedStore.contact_phone,
            pickupAreaMobilePhone: null,
            pickupAreaEmail: selectedStore.contact_email,
            pickupAreaAddress: selectedStore.address,
            pickupAreaZipCode: selectedStore.zip_code
        });

        setPickupLongitude(selectedStore.lng);
        setPickupLatitude(selectedStore.lat);

        setPickupArea({
            "label": selectedStore.address,
            "value": {}
        });
    };

    /**
     *
     */
    const showDrawerDeliveryOptions = () => {
        setVisibleDrawerDeliveryOptions(true);
        let data = {
            network_id: networkId,
            merchantTheme_id: merchantThemeId,
            merchant_id: merchantId
        };

        getCourseDeliveryOptions(data)
            .then(res => res.json())
            .then(data => {
                setDataNetworkDeliveryOptions(data.network_delivery_options);
                if (data.merchant_delivery_options !== null) {
                    setDataMerchantDeliveryOptions(data.merchant_delivery_options);
                }
                if (data.merchantTheme_delivery_options !== null) {
                    setDataMerchantThemeDeliveryOptions(data.merchantTheme_delivery_options);
                }
            });
    };

    /**
     *
     */
    const onCloseDeliveryOptions = () => {
        setVisibleDrawerDeliveryOptions(false);
    };

    /**
     *
     */
    const onSubmitDeliveryOptions = () => {
        let values = formDeliveryOptions.getFieldsValue();
        setSelectedDeliveryOptions(values);
        setVisibleDrawerDeliveryOptions(false);
    };

    const getDisabledHours = () => {
        let hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        let hoursNotDisable = [];
        let hoursToDisable = [];
        let date = form.getFieldValue('delivery_date');
        let dayOfWeek = moment(date).day();
        let openingSchedulesOfDay = [];

        if (openingSchedules.length === 0) {
            return hoursToDisable;
        } else {
            openingSchedules.forEach(openingSchedule => {
                if (openingSchedule.day === dayOfWeek) {
                    openingSchedulesOfDay.push(openingSchedule);
                }
            });

            openingSchedulesOfDay.forEach(openingScheduleOfDay => {
                let openingHour = (openingScheduleOfDay.opening_hours < 10 ? '0' : '') + openingScheduleOfDay.opening_hours;
                let openingMinuts = (openingScheduleOfDay.opening_minutes < 10 ? '0' : '') + openingScheduleOfDay.opening_minutes;
                let openingSeconds = (openingScheduleOfDay.opening_seconds < 10 ? '0' : '') + openingScheduleOfDay.opening_seconds;

                let closingHour = (openingScheduleOfDay.closing_hours < 10 ? '0' : '') + openingScheduleOfDay.closing_hours;
                let closingMinuts = (openingScheduleOfDay.closing_minutes < 10 ? '0' : '') + openingScheduleOfDay.closing_minutes;
                let closingSeconds = (openingScheduleOfDay.closing_seconds < 10 ? '0' : '') + openingScheduleOfDay.closing_seconds;

                let startTime = moment(openingHour + ":" + openingMinuts + ":" + openingSeconds, 'HH:mm:ss').format('HH:mm:ss');
                let endTime = closingHour + ":" + closingMinuts + ":" + closingSeconds;
                hours.forEach(hour => {
                    let time = moment(hour, 'HH').format('HH:mm:ss');
                    if (time >= startTime && time < endTime) {
                        hoursNotDisable.push(hour)
                        ;
                    }
                });
            });

            hours.forEach(hour => {
                let verifyHour = hoursNotDisable.includes(hour);
                if (verifyHour === false) {
                    hoursToDisable.push(hour);
                }
            });

            return hoursToDisable;
        }
    };

    /**
     *
     */
    useEffect(() => {
        fetchOpeningSchedules();

        if (screens.xs === true || screens.sm === true || screens.md === true) {
            setDirection('vertical');
        }

        if (screens.lg === true || screens.xl === true) {
            setDirection('horizontal');
        }

        if (user !== null) {
            form.setFieldsValue({
                pickupAreaAddress: user.merchant.address,
                pickupAreaContactFirstname: user.merchant.ownerFirstName,
                pickupAreaContactLastname: user.merchant.ownerLastName,
                pickupAreaEmail: user.merchant.ownerEmail,
                pickupAreaMobilePhone: user.merchant.ownerPhone,
                pickupAreaName: user.merchant.name,
                pickupAreaPhone: user.merchant.phone,
                pickupAreaZipCode: user.merchant.zip_code
            });

            setPickupArea({
                "label": user.merchant.address,
                "value": {}
            });

            setMerchantId(user.merchant.id);
            setNetworkId(user.merchant.network_id);
            setMerchantThemeId(user.merchant.merchantTheme_id);
            setPickupLatitude(user.merchant.latitude);
            setPickupLongitude(user.merchant.longitude);
            setOpeningSchedules(user.merchant.opening_schedules);

            if (user.merchant.commission !== null) {
                setCommission(user.merchant.commission.commission_price);
            } else {
                if (user.merchant.merchantTheme_id !== null) {
                    fetchCommissionMerchantTheme(user.merchant.merchantTheme_id);
                }
                fetchCommissionNetwork(user.merchant.network_id);
            }
        }

        form.setFieldsValue({
            delivery_date: moment()
        });
    }, [user, screens]);

    const optionsCustomer = dataCustomer.map(d => (
        <Option key={d.id} value={d.id} label={[d.name, d.firstname].join(' ')}>
            {d.name} {d.firstname} <CaretRightOutlined /> {d.address}
        </Option>
    ));
    const optionsMerchantStores = merchantStores.map(d => <Option key={d.id} value={d.id}>{d.store_name}</Option>);

    selectPickupArea = (
        <div>
            <Space>
                <Radio.Group onChange={onChangePickupArea} value={valuePickupArea} style={{ marginBottom: "20px" }}>
                    <Space direction={direction}>
                        <Radio value="merchant">Chez moi</Radio>
                        <Radio value="custom_merchant">Chez moi à une autre adresse</Radio>
                        <Radio value="custom">A une autre adresse</Radio>
                        <Radio value="store">Depuis un entrepôt</Radio>
                    </Space>
                </Radio.Group>
            </Space>
            <div style={{ marginBottom: '20px' }} hidden={hiddenSelectStores}>
                <Form.Item {...layout} name="store_id">
                    <Select
                        style={{ width: 250 }}
                        onChange={handleChangeSelectStore}
                        placeholder="Choisissez un entrepôt"
                    >
                        {optionsMerchantStores}
                    </Select>
                </Form.Item>
            </div>
            <Row gutter={[30, 20]}>
                <Col xs={24} lg={12}>
                    <Form.Item {...layout} name="pickupAreaName">
                        <Input name="pickupAreaName" placeholder="Nom du commerçant" disabled={disablePickupArea} />
                    </Form.Item>
                    <Form.Item {...layout} name="pickupAreaAddress">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                            selectProps={{
                                value: pickupArea,
                                onChange: onChangePickupAreaLocation,
                                isDisabled: disablePickupAreaAdress,
                                placeholder: 'Choisissez une adresse',
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ['fr'],
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="pickupAreaZipCode">
                        <Input name="pickupAreaZipCode" placeholder="Code Postal" disabled={disablePickupAreaAdress} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Row gutter={30}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item  {...layout} name="pickupAreaContactFirstname" >
                                <Input name="pickupAreaContactFirstname" placeholder="Prénom du contact" disabled={disablePickupArea} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item  {...layout} name="pickupAreaContactLastname" >
                                <Input name="pickupAreaContactLastname" placeholder="Nom du contact" disabled={disablePickupArea} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item  {...layout} name="pickupAreaPhone" >
                                <Input name="pickupAreaPhone" placeholder='Téléphone' disabled={disablePickupArea} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item  {...layout} name="pickupAreaMobilePhone" >
                                <Input name="pickupAreaMobilePhone" placeholder='Téléphone portable' disabled={disablePickupArea} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item {...layout} name="pickupAreaEmail" >
                        <Input name="pickupAreaEmail" placeholder='Email' disabled={disablePickupArea} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    selectDeliveryArea = (
        <div>
            <Row gutter={[16, 10]}>
                <Col xs={18} lg={22}>
                    <Form.Item
                        name="customer_id"
                        rules={[{
                            required: true,
                            message: "Merci de sélectionner un client"
                        }]}
                    >
                        <Select
                            placeholder="Sélectionnez un client (min. 3 caractères)"
                            showSearch
                            allowClear
                            showArrow
                            filterOption={false}
                            notFoundContent={null}
                            onSearch={handleSearchCustomer}
                            onChange={handleChangeCustomer}
                        >
                            {optionsCustomer}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={6} lg={2} style={{ textAlign: "right" }}>
                    <Space size="middle">
                        <Button type="primary" onClick={onShowCustomerForm}>
                            <PlusCircleOutlined />
                        </Button>
                        <Button type="primary" onClick={onClickEditCustomer} disabled={disableEditCustomerButton}>
                            <EditOutlined />
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row gutter={[16, 10]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name="deliveryAreaFirstname" >
                        <Input placeholder="Prénom du contact" disabled={disableDeliveryAreaAdress} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name="deliveryAreaLastname" >
                        <Input placeholder="Nom du contact" disabled={disableDeliveryAreaAdress} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 10]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name="deliveryAreaPhone" >
                        <Input placeholder="Téléphone" disabled={disableDeliveryAreaAdress} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name="deliveryAreaMobilePhone" >
                        <Input placeholder="Téléphone portable" disabled={disableDeliveryAreaAdress} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 10]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name="deliveryAreaAddress">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                            selectProps={{
                                value: deliveryArea,
                                onChange: onChangeDeliveryAreaLocation,
                                isDisabled: disableDeliveryAreaAdress,
                                placeholder: "Choisissez une adresse",
                                theme: themeSelect
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ["fr"],
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name="deliveryAreaZipCode">
                        <Input
                            placeholder="Code Postal"
                            disabled={disableDeliveryAreaAdress}
                            style={{ height: 38 }} // same heigth of GooglePlacesAutocomplete heigth unsettable
                        />
                    </Form.Item>
                </Col>
            </Row>
            <CustomerForm
                customerId={selectedCustomerId}
                action={actionCustomer}
                show={showCustomerForm}
                onClose={onCloseCustomerForm}
            />
        </div>
    );

    let valueCommission = 1;

    if (commission !== null) {
        valueCommission = commission;
    } else {
        if (commissionMerchantTheme !== null) {
            valueCommission = commissionMerchantTheme;
        } else {
            if (commissionNetwork !== null) {
                valueCommission = commissionNetwork;
            }
        }
    }

    networkDeliveryOptions = dataNetworkDeliveryOptions.map(d => {
        let optionName = null;
        if (d.option_name === null && d.parent !== null) {
            optionName = d.parent.option_name;
        } else {
            optionName = d.option_name;
        }
        let optionPrice = null;
        if (d.option_price !== null) {
            optionPrice = '(+' + Math.round((d.option_price * valueCommission) * 100) / 100 + ' €)'
        }
        return (
            <Col span={24}>
                <Checkbox value={d.id}>{optionName} {optionPrice}</Checkbox>
            </Col>
        )
    });

    if (dataMerchantDeliveryOptions.length > 0) {
        merchantDeliveryOptions = (
            <div>
                <Divider orientation='left'>Options du commerçant</Divider>
                <Row>
                    {dataMerchantDeliveryOptions.map(d => {
                        let optionName = null;
                        if (d.option_name === null && d.parent !== null) {
                            optionName = d.parent.option_name;
                        } else {
                            optionName = d.option_name;
                        }
                        let optionPrice = null;
                        if (d.option_price !== null) {
                            optionPrice = '(+' + Math.round((d.option_price * valueCommission) * 100) / 100 + ' €)'
                        }
                        return (
                            <Col span={24}>
                                <Checkbox value={d.id}>{optionName} {optionPrice}</Checkbox>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        );
    }

    if (dataMerchantThemeDeliveryOptions.length > 0) {
        merchantThemeDeliveryOptions = (
            <div>
                <Divider orientation='left'>Options du thème commerçant</Divider>
                <Row>
                    {dataMerchantThemeDeliveryOptions.map(d => {
                        let optionName = null;
                        if (d.option_name === null && d.parent !== null) {
                            optionName = d.parent.option_name;
                        } else {
                            optionName = d.option_name;
                        }
                        let optionPrice = null;
                        if (d.option_price !== null) {
                            optionPrice = '(+' + Math.round((d.option_price * valueCommission) * 100) / 100 + ' €)'
                        }
                        return (
                            <Col span={24}>
                                <Checkbox value={d.id}>{optionName} {optionPrice}</Checkbox>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        );
    }

    return (
        <Form form={form} name="layout" onFinish={onFinish} layout="vertical">
            <Divider orientation="left">Zone de Retrait</Divider>
            {selectPickupArea}
            <Divider orientation="left">Zone de Livraison</Divider>
            {selectDeliveryArea}
            <Divider orientation="left">Informations du colis</Divider>
            <Space>
                <Button type="primary" onClick={showDrawerPacket} className="button-course">
                    <DropboxOutlined /> Colis
                </Button>
                <Button type="primary" onClick={showDrawerDeliveryOptions}>
                    <UnorderedListOutlined /> Options de livraison
                </Button>
            </Space>
            <PacketForm
                packet={packetInformations}
                visible={visibleDrawerPacket}
                onSubmit={onSubmitPacket}
                onClose={onClosePacket}
            />
            <Drawer
                title="Options de livraison"
                width={720}
                onClose={onCloseDeliveryOptions}
                visible={visibleDrawerDeliveryOptions}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <Space>
                        <Button onClick={onCloseDeliveryOptions} style={{ marginRight: 8 }}>
                            Annuler
                        </Button>
                        <Button onClick={onSubmitDeliveryOptions} type="primary">
                            Enregistrer
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: "right" }}
            >
                <Form form={formDeliveryOptions} layout="vertical" hideRequiredMark >
                    <Form.Item name="delivery_options">
                        <Checkbox.Group >
                            <Divider orientation='left'>Options Générales</Divider>
                            <Row>
                                {networkDeliveryOptions}
                            </Row>
                            {merchantThemeDeliveryOptions}
                            {merchantDeliveryOptions}
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Drawer>
            <Divider orientation="left">Plannification</Divider>
            <Row gutter={24}>
                <Col xs={24} lg={8}>
                    <Form.Item {...layout} label="* Date de livraison" name="delivery_date" >
                        <DatePicker style={{ width: "250px" }} placeholder='Sélectionner une date' format='DD/MM/YYYY' disabledDate={disabledDate} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item {...layout} label="* Livraison à partir de " name="collect_time" >
                        <TimePicker showNow={false}  style={{ width: "250px" }} placeholder='Sélectionner une heure' format={timeFormat} minuteStep={15} disabledHours={getDisabledHours} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item {...layout} label="* Livraison avant" name="delivery_time">
                        <TimePicker showNow={false}  style={{ width: "250px" }} placeholder='Sélectionner une heure' format={timeFormat} minuteStep={15} disabledHours={getDisabledHours} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Space>
                <Button type="primary" htmlType="submit">
                    Valider
                </Button>
                <Button style={{ backgroundcolor: '#2FAC66' }}>
                    <Link to='/courses'>Annuler</Link>
                </Button>
            </Space>
        </Form>
    );
};

export default FormCourse;