import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function postCourse(data) {
    return fetch(config.hostApi + 'course/V2', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id
 * @returns 
 */
export function getOneCourse(id) {
    return fetch(config.hostApi + 'course/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} pickup 
 * @param {*} delivery 
 * @returns 
 */
export function getDistance(pickup, delivery) {
    let url = 'course/distance?origin=' + pickup + '&destination=' + delivery + '&mode=bicycling';
    return fetch(config.hostApi + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}


/**
 * 
 * @param {*} pickupLat 
 * @param {*} pickupLng 
 * @param {*} deliveryLat 
 * @param {*} deliveryLng 
 * @returns 
 */
export function getDeliveryLocationAllowed(pickupLat, pickupLng, deliveryLat, deliveryLng) {
    let url = 'course/deliverylocation/allowed?pickupLocation[]=' + pickupLat + '&pickupLocation[]=' + pickupLng
        + '&deliveryLocation[]=' + deliveryLat + '&deliveryLocation[]=' + deliveryLng;

    return fetch(config.hostApi + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} distance 
 * @param {*} tranports 
 * @param {*} packet_price 
 * @param {*} packet_weight 
 * @param {*} collectDateTime 
 * @returns 
 */
export function getPrice(distance, tranports, packet_price, packet_weight, collectDateTime, deliveryOptions) {
    let url = 'course/price?distance=' + distance + '&transports=' + tranports + '&weight='
        + packet_weight + '&packetPrice=' + packet_price + '&collectDateTime=' + collectDateTime;

    deliveryOptions.forEach((deliveryOption) => {
        url = url + "&deliveryOptions[]=" + deliveryOption;
    });

    return fetch(config.hostApi + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function checkRules(data) {
    return fetch(config.hostApi + 'course/checkrules', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {number} page
 * @param {number} limit
 * @param {Object} payload
 * @returns
 */
export function getAll(page, limit, payload) {
    let url = 'courses?page=' + page + '&limit=' + limit;

    return fetch(config.hostApi + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(payload)
    });
}

/**
 *
 * @param {number} page
 * @param {number} limit
 * @param {*} payload
 * @returns
 */
export function getTransport(data) {
    return fetch(config.hostApi + 'course/transport', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 *
 * @param {*} id
 * @param {*} confirm
 * @returns
 */
export function validateCourse(id, confirm) {
    let url = 'course/' + id + '/validate';
    return fetch(config.hostApi + url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(confirm)
    });
}

/**
 *
 * @returns
 */
export function getOpeningSchedules() {
    return fetch(config.hostApi + 'course/openingschedules' , {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}