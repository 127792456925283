import config from '../Config.js';

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getCustomers(data) {
    let dataToSend = 'customers';
    if (data.term === undefined || data.term === null ) {
        dataToSend = 'customers?current=' + data.current + '&pageSize=' + data.pageSize
    } else {
        if (data.current === undefined && data.pigeSize === undefined) {
            dataToSend = 'customers?term=' + data.term
        } else {
            dataToSend = 'customers?term=' + data.term + '&current=' + data.current + '&pageSize=' + data.pageSize;
        }
    }
    
    return fetch(config.hostApi + dataToSend, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getOneCustomer(id) {
    return fetch(config.hostApi + 'customer/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function postCustomer(data) {
    return fetch(config.hostApi + 'customer', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function updateCustomer(data) {
    return fetch(config.hostApi + 'customer', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    });
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function deleteCustomer(id) {
    return fetch(config.hostApi + 'customer/' + id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}

/**
 *
 * @param {*} id
 */
export function archive(id) {
    return fetch(config.hostApi + 'customer/' + id + '/archive', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    });
}