import config from '../Config.js';

/**
 * 
 * @param {*} id 
 * @returns 
 */
export function getOneNetwork(id) {
    return fetch(config.hostApi + 'network/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
    })
}
