import React, { useEffect } from 'react';
import moment from 'moment';

import {
    Table,
    Button,
    Tag,
    Layout,
    Col,
    Row,
    Drawer,
    Divider,
    Progress,
    Grid,
    DatePicker,
    Space,
    Form,
    PageHeader,
    Avatar,
    Image
} from 'antd';
import { EyeOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { getAll as getCourses, getOneCourse } from '../../Api/Courses.js';

const { Content } = Layout;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const translate = {
    canceled: 'Annulée',
    available: 'Emise',
    assigned: 'Assignée',
    started: 'Démarée',
    inProgress: 'Colis récupéré',
    isDelivered: 'Colis Livré',
    returned: 'Retournée',
    draft: 'Brouillon',
    failed: 'Echouée',
    pickupFailed: 'Impossible de récupérer le colis'
};

const layoutForm = {
    labelAlign: 'left',
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};

const defaultFilters = {
    deliveryDates: [moment(), moment().add(1, 'days')],
};

/**
 *
 * @param {*} props (filters)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({ ...props.filters });
        props.updateFilters({ ...props.filters });
    };

    return (
        <Form
            {...layoutForm}
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={{ ...props.filters }}
        >
            <Form.Item
                label="Date de livraison entre le "
                name="deliveryDates"
            >
                <RangePicker className="filter" format={dateFormat} allowClear={false} />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Filtrer
                    </Button>
                    <Button onClick={clearFilter}>
                        Annuler
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

/**
 *
 * @returns
 */
const Courses = () => {

    const [paginate, setPaginate] = React.useState({ current: 1, pageSize: 50 });
    const [loading, setLoading] = React.useState(false);
    const [courses, setCourses] = React.useState([]);
    const [visibleDrawerCourseInfo, setVisibleDrawerCourseInfo] = React.useState(false);
    const [courseEdit, setCourseEdit] = React.useState(null);
    const [courseId, setCourseId] = React.useState(null);
    const [filters, setFilters] = React.useState({ ...defaultFilters });
    const [size, setSize] = React.useState(600);

    const screens = useBreakpoint();

    /**
     *
     */
    const columns = [
        {
            title: 'UUID',
            dataIndex: 'short_uuid',
            key: 'short_uuid',
            width: 50,
        },
        {
            title: 'Collecte',
            dataIndex: 'pickup_area',
            key: 'pickup_area',
            responsive: ['lg'],
            render: (pickup_area) => {
                if (pickup_area == null) {
                    return (
                        <div></div>
                    )
                } else {
                    return (
                        <div>{pickup_area.name}</div>
                    )
                }
            }
        },
        {
            title: 'Client',
            dataIndex: 'customer',
            key: 'customer',
            responsive: ['lg'],
            render: (customer) => {
                if (customer == null) {
                    return (
                        <div></div>
                    )
                } else {
                    return (
                        <div>{customer.firstname} {customer.name}</div>
                    )
                }
            }
        },
        {
            title: 'Date',
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            render: deliveryDate => {
                return (deliveryDate) ? moment(deliveryDate).format('DD/MM/YYYY') : null;
            }
        },
        {
            title: 'À partir de',
            dataIndex: 'collectTime',
            key: 'id',
            responsive: ['lg']
        },
        {
            title: 'Avant',
            dataIndex: 'deliveryTime',
            key: 'id',
            responsive: ['lg']
        },
        {
            title: 'Distance',
            key: 'id',
            responsive: ['lg'],
            render: (row) => (
                <div>{row.distance / 1000} km</div>
            )
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag color="green" key={status}>
                    {translate[status]}
                </Tag>
            ),
        },
        {
            title: 'Prix HT',
            dataIndex: 'driverPrice',
            key: 'price',
            render: (data) => {
                return (
                    <div>{data} €</div>
                );
            },
        },
        {
            title: '',
            key: 'course',
            render: (course) => (
                <div>
                    <Button size="small" type='primary' onClick={() => showDrawerCourseInfo(course.id)}>
                        <EyeOutlined />
                    </Button>
                </div>
            )
        },
    ];

    /**
     *
     */
    const getPayload = () => {
        let payload = {
            status: ['available', 'assigned', 'started', 'inProgress', 'isDelivered', 'failed', 'pickupFailed', 'canceled', 'returned']
        };

        if (!filters.deliveryDates.includes(null)) {
            payload.dateStart = filters.deliveryDates[0].format('YYYY-MM-DD');
            payload.dateEnd = filters.deliveryDates[1].format('YYYY-MM-DD');
        }

        return payload;
    };

    /**
     *
     * @param {*} pagination (from Ant Table)
     * @param {*} filter (useless from Ant Table)
     * @param {*} sorter (useless from Ant Table)
     * @param {*} dataSource (useless from Ant Table)
     * @param {*} newFilter
     */
    const fetchCourses = (pagination, filter, sorter, dataSource, newFilter=false) => {
        setLoading(true);

        const page = (newFilter) ? 1 : pagination.current;
        const limit = pagination.pageSize;
        const payload = getPayload();

        getCourses(page, limit, payload)
            .then((res) => res.json())
            .then((json) => {
                let coursesWithKey = json.courses;
                coursesWithKey.forEach((course) => {
                    course.key = course.id
                });

                setLoading(false);
                setCourses(coursesWithKey);
                setPaginate({
                    current: (newFilter) ? 1 : pagination.current,
                    pageSize: pagination.pageSize,
                    total: json.totalCount
                });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    /**
     *
     * @param {*} id
     */
    const showDrawerCourseInfo = (id) => {
        setVisibleDrawerCourseInfo(true);
        getOneCourse(id)
            .then((res) => res.json())
            .then((json) => {
                setCourseEdit(json.course);
                setCourseId(json.course.short_uuid)
            });
    };

    /**
     *
     */
    const onCloseCourseInfo = () => {
        setVisibleDrawerCourseInfo(false);
    };

    /**
     *
     */
    useEffect(() => {
        fetchCourses(paginate);
    }, []);

    /**
     *
     */
    useEffect(() => {
        if (screens.xs === true || screens.sm === true || screens.md === true) {
            setSize(300);
        }

        if (screens.lg === true || screens.xl === true) {
            setSize(600);
        }
    }, [screens]);

    /**
     *
     */
    useEffect(() => {
        fetchCourses(paginate, {}, {}, {}, true);
    }, [filters]);

    let drawerInfo = null;
    if (courseEdit) {
        let deliveryOptions = [];
        let deliveryAreaComment = null;
        let courseDriver = {
            firstName: '',
            lastName: '',
            phone: ''
        };
        let courseCustomer = {
            firstName: '',
            lastName: '',
        };

        let packetPrice = courseEdit.packet_price || 0;
        let packetWeight = courseEdit.packet_weight || 0;

        let options = courseEdit.delivery_options;
        options.forEach((option) => {
            let name = option.option_name;
            if (option.parent_id) {
                name = option.parent.option_name;
            }

            let chooseOption = {
                name: name,
            };
            deliveryOptions.push(chooseOption);
        });

        if (courseEdit.driver) {
            courseDriver.firstName = courseEdit.driver.firstName || '';
            courseDriver.lastName = courseEdit.driver.lastName || '';
            courseDriver.phone = courseEdit.driver.phone || '';
        }

        if (courseEdit.delivery_area) {
            courseCustomer.firstName = courseEdit.delivery_area.firstname || '';
            courseCustomer.lastName = courseEdit.delivery_area.name || '';

            if (courseEdit.delivery_area.comment) {
                deliveryAreaComment = courseEdit.delivery_area.comment.replace(/ *\#[^)]*\# */g, " ").trim();
            }
        }

        let percent = 0;
        let statut = 'normal';
        switch (courseEdit.status) {
            case 'assigned':
                percent = 25;
                break;
            case 'started':
                percent = 50;
                break;
            case 'inProgress':
                percent = 75;
                break;
            case 'isDelivered':
                percent = 100;
                statut = 'success';
                break;
            case 'returned':
                percent = 0;
                statut = 'exception';
                break;
            case 'canceled':
                percent = 0;
                statut = 'exception';
                break;
            case 'pickupFailed':
                percent = 25;
                statut = 'exception';
                break;
            case 'failed':
                percent = 75;
                statut = 'exception';
                break;
            default:
                break;
        };

        let signatureContent = [];
        courseEdit.statutes.filter(status => (status.name === 'isDelivered')).forEach(status => {
            if (status.comment) {
                signatureContent.push(
                    <Row >
                        <Col span={12}>Commentaire de réception :</Col>
                        <Col span={12}>{status.comment}</Col>
                    </Row>
                );
            }

            if (status.signature) {
                signatureContent.push(
                    <Row >
                        <Col span={12}>Récuperer par :</Col>
                        <Col span={12}>{status.signature.firstname} {status.signature.lastname}</Col>
                    </Row>
                );

                if (status.signature.picture) {
                    let src = status.signature.picture;

                    signatureContent.push(
                        <Row >
                            <Col span={12}>Signature :</Col>
                            <Col span={12}><Image width={200} src={src} /></Col>
                        </Row>
                    );
                }
            }
        });

        drawerInfo = (
            <div>
                <Divider orientation="left">
                    Etat de la course
                </Divider>
                <div style={{ width: size - 70 }}>
                    <Progress
                        percent={percent}
                        status={statut}
                        format={() => translate[courseEdit.status]}
                        strokeColor={{
                            '0%': '#0B4624',
                            '100%': '#2FAC66',
                        }}
                    />
                </div>
                <Divider orientation="left">
                    Informations générales
                </Divider>
                <Row>
                    <Col span={12}>Date de livraison : </Col>
                    <Col span={12}>{moment(courseEdit.deliveryDate).format('DD/MM/YYYY')}</Col>
                </Row>
                <Row>
                    <Col span={12}>Heure de retrait :</Col>
                    <Col span={12}>{courseEdit.collectTime}</Col>
                </Row>
                <Row>
                    <Col span={12}>Heure de livraison :</Col>
                    <Col span={12}>{courseEdit.deliveryTime}</Col>
                </Row>

                <Row >
                    <Col span={12}>Distance de la course:</Col>
                    <Col span={12}>{courseEdit.distance / 1000} km </Col>
                </Row>
                <Row >
                    <Col span={12}>Prix HT (commerçant) :</Col>
                    <Col span={12}>{courseEdit.merchantPrice} € </Col>
                </Row>
                <Divider orientation="left">Coursier</Divider>
                <Row>
                    <Col span={12}>Nom :</Col>
                    <Col span={12}>{courseDriver.firstName} {courseDriver.lastName}</Col>
                </Row>
                <Row>
                    <Col span={12}>Téléphone :</Col>
                    <Col span={12}>{courseDriver.phone}</Col>
                </Row>
                <Divider orientation="left">Livraison</Divider>
                <Row>
                    <Col span={12}>Nom :</Col>
                    <Col span={12}>{courseCustomer.firstName} {courseCustomer.lastName}</Col>
                </Row>
                <Row>
                    <Col span={12}>Adresse :</Col>
                    <Col span={12}>{courseEdit.delivery_area.address}</Col>
                </Row>
                <Row>
                    <Col span={12}>Téléphone :</Col>
                    <Col span={12}>{courseEdit.delivery_area.phone}</Col>
                </Row>
                <Row >
                    <Col span={12}>Portable :</Col>
                    <Col span={12}>{courseEdit.delivery_area.mobile_phone}</Col>
                </Row>
                <Row >
                    <Col span={12}>Etage :</Col>
                    <Col span={12}>{courseEdit.delivery_area.floor}</Col>
                </Row>
                <Row >
                    <Col span={12}>Interphone :</Col>
                    <Col span={12}>{courseEdit.delivery_area.intercom_code}</Col>
                </Row>
                <Row>
                    <Col span={12}>Commentaire :</Col>
                    <Col span={12}>{deliveryAreaComment}</Col>
                </Row>
                <br />
                {signatureContent}
                <Divider orientation="left">Colis</Divider>
                <Row>
                    <Col span={12}>Prix :</Col>
                    <Col span={12}>{packetPrice} €</Col>
                </Row>
                <Row>
                    <Col span={12}>Poids :</Col>
                    <Col span={12}>{packetWeight} kg</Col>
                </Row>
                <Row>
                    <Col span={12}>Description :</Col>
                    <Col span={12}>{courseEdit.packetDescription}</Col>
                </Row>
                <Divider orientation="left">Options de livraison</Divider>
                {deliveryOptions.map((deliveryOption) => (
                    <Row gutter={20}>
                        <Col span={16}>- {deliveryOption.name}</Col>
                    </Row>
                ))}
            </div>
        );
    }

    return (
        <div>
            <Content style={{ margin: '16px 16px' }}>
                <div className="site-layout-background" style={{ minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        avatar={{ src: <Avatar icon={<UnorderedListOutlined />} /> }}
                        title="LISTE DES COURSES"
                    />
                    <Divider />
                    <Filter filters={{ ...defaultFilters }} updateFilters={setFilters} />
                    <Divider />
                    <Table
                        columns={columns}
                        loading={loading}
                        onChange={fetchCourses}
                        pagination={{
                            current: paginate.current,
                            pageSize: paginate.pageSize,
                            showSizeChanger: true,
                            total: paginate.total
                        }}
                        dataSource={courses}
                    />
                    <Drawer
                        title={"Course N°" + courseId}
                        width={size}
                        onClose={onCloseCourseInfo}
                        visible={visibleDrawerCourseInfo}
                        bodyStyle={{ paddingBottom: 80 }}
                    >
                        {drawerInfo}
                    </Drawer>
                </div>
            </Content>
        </div>
    );
};

export default Courses;