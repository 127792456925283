import React from 'react';

import { Table, Space, Button, Layout, Divider, Input, Popconfirm, Tooltip } from 'antd';
import { UnorderedListOutlined, FormOutlined, InboxOutlined } from '@ant-design/icons';

import { deleteCustomer, getCustomers, archive as archiveCustomer } from '../../Api/Customers.js';
import { GlobalContext } from '../../GlobalContext.js';

import CustomerForm from '../../Components/CustomerForm.js';

class Customers extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            paginate: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            customer_name: null,
            showCustomerForm: false,
            id: null,
            actionCustomer: 'save'
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Nom',
                key: 'name',
                render: (data) => (
                    <div>{data.name} {data.firstname}</div>
                )
            },
            {
                title: 'Adresse',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <Space >
                        <Button size="small" type='primary' onClick={(e) => this.onClickEditCustomer(id, e)}>
                            <FormOutlined />
                        </Button>
                        <Popconfirm
                            title="Êtes-vous sûr de vouloir archiver ce commerçant ?"
                            onConfirm={(e) => this.confirmArchiveCustomer(id, e)}
                            okText="Oui"
                            cancelText="Non"
                            placement="topRight"
                        >
                            <Tooltip title="Archiver" placement="left">
                                <Button size="small" type='primary' danger className='button-table'>
                                    <InboxOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                )
            }
        ];

        this.onSearchCustomerName = this.onSearchCustomerName.bind(this);
        this.onClickFilterCustomers = this.onClickFilterCustomers.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.confirmDeleteCustomer = this.confirmDeleteCustomer.bind(this);
        this.onClickEditCustomer = this.onClickEditCustomer.bind(this);
    }

    /**
     * 
     * @param {*} params 
     */
    loadCustomers(params = {}) {
        this.setState({ loading: true })
        let pagination = {
            current: this.state.paginate.current,
            pageSize: this.state.paginate.pageSize
        }
        getCustomers(pagination)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    customers: json.customer,
                    pagination: {
                        ...params.paginate,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadCustomers({ pagination });
    }

    /**
     * 
     * @param {*} pagination 
     * @param {*} filters 
     * @param {*} sorter 
     */
    handleTableChange = (pagination, filters, sorter) => {
        let data = {
            term: this.state.customer_name,
            current: pagination.current,
            pageSize: pagination.pageSize
        }
        getCustomers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    customers: json.customer,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                })
            })
    };

    /**
     * 
     */
    onClickFilterCustomers() {
        const paginate = this.state.paginate;
        let customer_name = this.state.customer_name;
        let data = {
            current: paginate.current,
            pageSize: paginate.pageSize,
            term: customer_name
        };

        getCustomers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    customers: json.customer,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        this.setState({
            customer_name: null
        })
        const pagination = {
            current: 1,
            pageSize: 10
        };
        this.loadCustomers({ pagination });
    }

    /**
     * 
     * @param {*} e 
     */
    onSearchCustomerName = (e) => {
        if (e.target.value.length > 2) {
            this.setState({ customer_name: e.target.value })
        }
    };

    /**
     * 
     */
    onShowCustomerForm = () => {
        this.setState({
            id: null,
            showCustomerForm: true,
            actionCustomer: 'save'
        });
    };

    /**
     *
     */
    onCloseCustomerForm = () => {
        this.setState({ showCustomerForm: false }, () => {
            this.loadCustomers();
        });
    };

    /**
     * 
     * @param {*} id 
     */
    onClickEditCustomer = (id) => {
        this.setState({
            id: id,
            showCustomerForm: true,
            actionCustomer: 'update'
        });
    };

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    confirmDeleteCustomer(id, e) {
        e.preventDefault();
        deleteCustomer(id)
            .then((res) => res.json())
            .then(() => {
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                this.loadCustomers({ pagination });
            })
    }

    /**
     *
     * @param {*} id
     * @param {*} e
     */
    confirmArchiveCustomer(id, e) {
        e.preventDefault();
        archiveCustomer(id)
            .then((res) => res.json())
            .then(() => {
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                this.loadCustomers({ pagination });
            });
    }

    render() {
        const { Content } = Layout;
        const { customers, pagination, loading, id, actionCustomer, showCustomerForm } = this.state;

        return (
            <div>
                <Content style={{ margin: '16px 16px' }}>
                    <div className="site-layout-background" style={{ minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> LISTE DES CLIENTS</h2>
                        <Divider />
                        <Space direction="vertical">
                            <Space>
                                <div className="filter-title">Nom</div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onSearchCustomerName}
                                    className="filter"
                                />
                            </Space>
                            <Space>
                                <Button className="filter-button" type="primary" onClick={this.onClickFilterCustomers}>
                                    Filtrer
                                </Button>
                                <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        <Divider />
                        <Button
                            style={{ marginBottom: 20 }}
                            type="primary"
                            onClick={this.onShowCustomerForm}
                        >
                            Ajouter un client
                        </Button>
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={customers}
                        />
                        <CustomerForm
                            customerId={id}
                            action={actionCustomer}
                            show={showCustomerForm}
                            onClose={this.onCloseCustomerForm}
                        />
                    </div>
                </Content>
            </div>
        );
    }
}

export default Customers;