import React from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import {
    Radio,
    Divider,
    Layout,
    Button,
    Space,
    Select,
    TimePicker,
    Row,
    Col,
    Drawer,
    Form,
    PageHeader,
    Avatar
} from 'antd';
import { ArrowRightOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import { getAll as getCourses, getOneCourse } from '../../Api/Courses.js';

import config from '../../Config.js';
import { GlobalContext } from '../../GlobalContext.js';
import moment from 'moment';

mapboxgl.accessToken = config.mapboxToken;

const { Content } = Layout;
const { Option } = Select;

const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';
const layoutForm = {
    labelAlign: 'left',
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};
const layoutFormItem = {
    style: {
        marginBottom: 10
    }
};

const defaultFilters = {
    day: 'today',
    status: ['available', 'assigned', 'started', 'inProgress'],
    pickupTime: [null, null],
    deliveryTime: [null, null],
};

/**
 *
 * @param {*} data
 * @returns array
 */
const createFeatureDetail = (data) => {
    let featureDetailDelivery = {};
    let featureDetailPickup = {};
    let deliveryLat = null;
    let deliveryLng = null;
    let pickupLat = null;
    let pickupLng = null;

    if (data.delivery_area) {
        deliveryLat = data.delivery_area.lat;
        deliveryLng = data.delivery_area.lng;
    }
    if (data.pickup_area) {
        pickupLat = data.pickup_area.lat;
        pickupLng = data.pickup_area.lng;
    }

    featureDetailDelivery = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [deliveryLng, deliveryLat]
        },
        properties: {
            course_id: data.id,
        }
    };
    featureDetailPickup = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [pickupLng, pickupLat]
        },
        properties: {
            course_id: data.id,
        }
    };

    return [featureDetailDelivery, featureDetailPickup];
};

/**
 *
 * @param {*} props (filters)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({ ...props.filters });
        props.updateFilters({ ...props.filters });
    };

    return (
        <Form
            {...layoutForm}
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={{ ...props.filters }}
        >
            <Form.Item {...layoutFormItem} name="day">
                <Radio.Group>
                    <Radio value="today">Aujourd'hui</Radio>
                    <Radio value="tomorrow">Demain</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item {...layoutFormItem} name="pickupTime" label="Heure de retrait">
                <TimePicker.RangePicker
                    style={{ width: '100%' }}
                    placeholder={['Heure de début', 'Heure de fin']}
                    format={timeFormat}
                />
            </Form.Item>
            <Form.Item {...layoutFormItem} name="deliveryTime" label="Heure de livraison">
                <TimePicker.RangePicker
                    style={{ width: '100%' }}
                    placeholder={['Heure de début', 'Heure de fin']}
                    format={timeFormat}
                />
            </Form.Item>
            <Form.Item {...layoutFormItem} name="status" label="Status des courses">
                <Select
                    mode="multiple"
                    placeholder='Sélectionner un ou plusieurs statuts'
                >
                    <Option value="available">Emise</Option>
                    <Option value="assigned">Assignée</Option>
                    <Option value="started">Démarée</Option>
                    <Option value="inProgress">Colis Récupéré</Option>
                    <Option value="isDelivered">Colis livré</Option>
                    <Option value="failed">Echouée (Livraison)</Option>
                    <Option value="pickupFailed">Echouée (Retrait)</Option>
                    <Option value="canceled">Annulée</Option>
                    <Option value="returned">Retournée</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Filtrer
                    </Button>
                    <Button onClick={clearFilter}>
                        Annuler
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

/**
 *
 * @returns
 */
class MapMerchant extends React.Component {

    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            deliveryArea: [],
            pickupArea: [],
            courses: [],
            dashboard: null,
            value: 'today',
            lng: 2,
            lat: 46.7,
            zoom: 5.4,
            activeIndex: 0,
            visibleDeliveryAreaDrawer: false,
            visiblePickupAreaDrawer: false,
            course: null,
            screenWidth: null,
            size: 500,
            filters: { ...defaultFilters },
        };

        this.mapContainer = React.createRef();

        this.setFilters = this.setFilters.bind(this);
        this.onCloseDeliveryDrawer = this.onCloseDeliveryDrawer.bind(this);
        this.onClosePickupDrawer = this.onClosePickupDrawer.bind(this);

        this.map = null;
        this.markers = [];
    }

    /**
     *
     */
    async componentDidMount() {
        let { lng, lat, zoom } = this.state;

        if (this.context.global.profile !== null) {
            lng = this.context.global.profile.merchant.longitude;
            lat = this.context.global.profile.merchant.latitude;
            zoom = 12;
        }

        if (window.innerWidth < 767) {
            this.setState({ size: 300 })
        }

        if (window.innerWidth > 768) {
            this.setState({ size: 500 })
        }

        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
        });

        await this.loadData();

        setInterval(() => {
            this.loadData(false);
        }, 15000)
    }

    /**
     *
     */
    async loadData(fitBounds=true) {
        await this.loadCourses();
        await this.buildMap(fitBounds);
    }

    /**
     *
     */
    getPayload() {
        const filters = this.state.filters;

        let payload = {
            dateStart: moment().format(dateFormat),
            dateEnd: moment().format(dateFormat),
        };

        if (filters.day === "tomorrow") {
            payload.dateStart = moment().add(1, 'days').format(dateFormat);
            payload.dateEnd = moment().add(1, 'days').format(dateFormat);
        }
        if (!filters.pickupTime.includes(null)) {
            payload.pickupTimeStart = filters.pickupTime[0].format(timeFormat);
            payload.pickupTimeEnd = filters.pickupTime[1].format(timeFormat);
        }
        if (!filters.deliveryTime.includes(null)) {
            payload.deliveryTimeStart = filters.deliveryTime[0].format(timeFormat);
            payload.deliveryTimeEnd = filters.deliveryTime[1].format(timeFormat);
        }
        if (filters.status.length > 0) {
            payload.status = filters.status;
        }

        return payload;
    }

    /**
     *
     */
    async loadCourses() {
        const page = 1;
        const limit = 0;
        const payload = this.getPayload();

        await getCourses(page, limit, payload)
            .then((res) => res.json())
            .then((json) => {
                let data = json.courses;
                let geoJsonDelivery = {};
                let featureDelivery = [];

                let geoJsonPickup = {};
                let featurePickup = [];

                data.forEach((d) => {
                    let featureDetail = createFeatureDetail(d);
                    featureDelivery.push(featureDetail[0]);
                    featurePickup.push(featureDetail[1]);
                });

                geoJsonDelivery = {
                    type: 'featureCollection',
                    features: featureDelivery
                };

                geoJsonPickup = {
                    type: 'featureCollection',
                    features: featurePickup
                };

                this.setState({
                    courses: data,
                    deliveryArea: geoJsonDelivery,
                    pickupArea: geoJsonPickup
                });
            });
    }

    /**
     *
     * @param {boolean} fitBounds
     */
    buildMap(fitBounds) {
        for (var i = this.markers.length - 1; i >= 0; i--) {
            this.markers[i].remove();
        }

        if (this.state.deliveryArea.length === 0) {
            return;
        }

        if (this.state.pickupArea.length === 0) {
            return;
        }

        let map = this.map;
        let markers = this.markers;
        let bounds = null;

        let geojsonDelivery = this.state.deliveryArea;
        let geojsonPickup = this.state.pickupArea;

        if (geojsonDelivery.features.length > 0 || geojsonPickup.features.length > 0) {
            if (fitBounds) {
                bounds = new mapboxgl.LngLatBounds();
            }

            if (geojsonDelivery.features.length !== 0) {
                geojsonDelivery.features.forEach((markerDelivery) => {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-delivery';

                    if (markerDelivery.geometry !== undefined) {
                        if (fitBounds) {
                            bounds.extend(markerDelivery.geometry.coordinates)
                        }

                        // make a marker for each feature and add to the map
                        let marker = new mapboxgl.Marker(el, {
                            offset: [5, -10]
                        })
                            .setLngLat(markerDelivery.geometry.coordinates)
                            .addTo(map);

                        markers.push(marker);

                        marker.getElement('el').addEventListener('click', () => {
                            this.setState({ visibleDeliveryAreaDrawer: true })
                            let courseId = markerDelivery.properties.course_id;
                            getOneCourse(courseId)
                                .then((res) => res.json())
                                .then((json) => {
                                    this.setState({ course: json.course })
                                })
                        })
                    }
                });
            }

            if (geojsonPickup.features.length !== 0) {
                geojsonPickup.features.forEach((markerPickup) => {
                    // create a HTML element for each feature
                    var el = document.createElement('div');
                    el.className = 'marker-pickup';
                    if (markerPickup.geometry !== undefined) {

                        if (fitBounds) {
                            bounds.extend(markerPickup.geometry.coordinates)
                        }

                        // make a marker for each feature and add to the map
                        let marker = new mapboxgl.Marker(el, {
                            offset: [5, -10]
                        })
                            .setLngLat(markerPickup.geometry.coordinates)
                            .addTo(map);

                        markers.push(marker);

                        marker.getElement('el').addEventListener('click', () => {
                            this.setState({ visiblePickupAreaDrawer: true })
                            let courseId = markerPickup.properties.course_id;
                            getOneCourse(courseId)
                                .then((res) => res.json())
                                .then((json) => {
                                    this.setState({ course: json.course })
                                })
                        })
                    }
                });
            }
        }

        if (bounds !== null && fitBounds) {
            map.fitBounds(bounds, { padding: 100, animate: false });
        }

        map.scrollZoom.disable();
        map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

        map.on("wheel", event => {
            if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
                event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
            } else {
                if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
            }
        });

        map.on('load', function () {
            map.resize();
        });

        // Clean up on unmount
        return () => map.remove();
    }

    /**
     * 
     */
    onCloseDeliveryDrawer() {
        this.setState({ visibleDeliveryAreaDrawer: false })
    }

    /**
     * 
     */
    onClosePickupDrawer() {
        this.setState({ visiblePickupAreaDrawer: false })
    }

    /**
     *
     * @param {*} values
     */
    setFilters(values) {
        this.setState({ filters: values }, () => this.loadData());
    }

    render() {
        let course = this.state.course;

        let drawerInfoDelivery = null;
        let drawerInfoPickup = null;
        let mobilePhone = null;
        let phone = null;
        let firstnameCustomer = null;
        let firstNameDriver = null;
        let lastNameDriver = null;
        let statut = null;
        let transport = null;
        const translate = {
            canceled: 'Annulée',
            available: 'Emise',
            assigned: 'Assignée',
            started: 'Démarée',
            inProgress: 'Colis récupéré',
            isDelivered: 'Colis livré',
            returned: 'Retournée',
            draft: 'Brouillon',
            failed: 'Echouée',
            pickupFailed: 'Impossible de récupérer le colis'
        }
        const translateTransport = {
            walk: 'A pied',
            bike: 'Vélo',
            cargoBike: 'Vélo cargo',
            motorbike: 'Scooter',
            car: 'Voiture',
            commercialVehicle: 'Véhicule commercial'
        }
        if (course !== null) {
            statut = translate[course.status];
            transport = translateTransport[course.transports];

            if (course.delivery_area.mobile_phone === null) {
                mobilePhone = "";
            } else {
                mobilePhone = course.delivery_area.mobile_phone;
            }

            if (course.delivery_area.phone === null) {
                phone = "";
            } else {
                phone = course.delivery_area.phone;
            }

            if (course.delivery_area.firstname === null) {
                firstnameCustomer = ""
            } else {
                firstnameCustomer = course.delivery_area.firstname
            }

            if (course.driver) {
                firstNameDriver = course.driver.firstName;
                lastNameDriver = course.driver.lastName;
            }

            drawerInfoDelivery = (
                <div>
                    <Row gutter={20}>
                        <Col span={10}>Horaires :</Col>
                        <Col span={12}>{course.collectTime} <ArrowRightOutlined /> {course.deliveryTime} </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Adresse de départ :</Col>
                        <Col span={12}>{course.pickup_area.address}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Adresse d'arrivée :</Col>
                        <Col span={12}>{course.delivery_area.address}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Commerçant : </Col>
                        <Col span={12}>{course.pickup_area.name}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Client :</Col>
                        <Col span={12}>{firstnameCustomer} {course.delivery_area.name}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Coursier :</Col>
                        <Col span={12}>{firstNameDriver} {lastNameDriver}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Fixe :</Col>
                        <Col span={12}>{phone}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Mobile :</Col>
                        <Col span={12}>{mobilePhone}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Statut :</Col>
                        <Col span={12}>{statut}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Transport :</Col>
                        <Col span={12}>{transport}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Description :</Col>
                        <Col span={12}>{course.packetDescription}</Col>
                    </Row>
                </div>
            );

            drawerInfoPickup = (
                <div>
                    <Row gutter={20}>
                        <Col span={10}>Horaires :</Col>
                        <Col span={12}>{course.collectTime} <ArrowRightOutlined /> {course.deliveryTime} </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Adresse de départ :</Col>
                        <Col span={12}>{course.pickup_area.address}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Adresse d'arrivée :</Col>
                        <Col span={12}>{course.delivery_area.address}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Commerçant : </Col>
                        <Col span={12}>{course.pickup_area.name}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Client :</Col>
                        <Col span={12}>{firstnameCustomer} {course.delivery_area.name}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Coursier :</Col>
                        <Col span={12}>{firstNameDriver} {lastNameDriver}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Fixe :</Col>
                        <Col span={12}>{phone}</Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>Mobile :</Col>
                        <Col span={12}>{mobilePhone}</Col>
                    </Row>
                </div>
            );
        }

        return (
            <Content>
                <div style={{ margin: '16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <PageHeader
                            className="site-page-header"
                            avatar={{ src: <Avatar icon={<ShoppingCartOutlined />} /> }}
                            title="ACTIVITE DE LA JOURNEE"
                            subTitle="(Mise à jour automatique toutes les 15s)"
                        />
                        <Divider />
                        <Filter filters={defaultFilters} updateFilters={this.setFilters} />
                        <Divider></Divider>
                        <div className='map-page-container'>
                            <div ref={this.mapContainer} className="map-container" />
                        </div>
                    </div>
                </div>
                <Drawer
                    title="Zone de livraison souhaitée"
                    placement="right"
                    onClose={this.onCloseDeliveryDrawer}
                    visible={this.state.visibleDeliveryAreaDrawer}
                    width={this.state.size}
                >
                    {drawerInfoDelivery}
                </Drawer>
                <Drawer
                    title="Zone de retrait souhaitée"
                    placement="right"
                    onClose={this.onClosePickupDrawer}
                    visible={this.state.visiblePickupAreaDrawer}
                    width={this.state.size}
                >
                    {drawerInfoPickup}
                </Drawer>
            </Content>
        );
    }
}

export default MapMerchant;
