import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useAuth } from '../../auth';

const layout = {
    labelCol: { span: 20 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { offset: 0, span: 6 },
};

/**
 *  
 * @returns 
 */
const FormLogin = () => {
    const history = useHistory();
    const auth = useAuth();

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        let resolved = () => {
            history.push("/courses");
        };

        let rejected = () => {
            notification['error']({
                message: 'Les identifiants saisis ne correspondent pas',
            });
        };

        auth.signin(values, resolved, rejected);
    };

    return (
        <Form {...layout} onFinish={onFinish} >
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your username!' }]} >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]} >
                <Input.Password placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    CONNEXION
                </Button>
            </Form.Item>
            <div style={{ textAlign: "left" }}>
                <a href="https://www.applicolis.com/#itt_solution_form">Pas de compte, contactez-nous</a>
            </div>
        </Form>
    )
};

export default FormLogin;