import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Form, Input, Button, Space, Drawer, notification, Grid, message } from 'antd';

import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { postCustomer, getOneCustomer, updateCustomer } from '../Api/Customers.js';

const { TextArea } = Input;
const { useBreakpoint } = Grid;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const CustomerForm = (props) => {
    const { customerId, show, action } = props;
    const screens = useBreakpoint();

    const [form] = Form.useForm();

    let [location, setLocation] = useState(null);
    let [widthDrawer, setWidthDrawer] = useState(600);
    let [titleCustomerDrawer, setTitleCustomerDrawer] = useState(null);

    /**
     *
     * @param {*} location
     */
    const onChangeLocation = (location) => {
        setLocation(location);

        geocodeByAddress(location.label)
            .then(results => {
                let zipCode = null;
                const addressResults = results[0].address_components;

                addressResults.map(addressResult => {
                    const types = addressResult.types;
                    types.map(type => {
                        if (type === 'postal_code') {
                            zipCode = addressResult.long_name;
                        }
                    });
                });

                form.setFieldsValue({
                    customer_address: location.label,
                    customer_zip_code: zipCode
                });
            });
    };

    /**
     *
     */
    const onFinish = () => {
        form.validateFields()
            .then(async (values) => {
                geocodeByAddress(location.label)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        let customer = {
                            name: values.customer_name,
                            firstName: values.customer_firstName,
                            phone: values.customer_phone,
                            mobilePhone: values.customer_mobilePhone,
                            email: values.customer_email,
                            floor: values.customer_floor,
                            intercomCode: values.customer_intercomCode,
                            comment: values.customer_comment,
                            zip_code: values.customer_zip_code,
                            address: location.label,
                            longitude: lng,
                            latitude: lat,
                        };

                        if (action === 'save') {
                            postCustomer(customer)
                                .then((res) => res.json())
                                .then((data) => {
                                    onCloseCustomer(data.customer.id);
                                    notification['success']({
                                        message: 'Le client a bien été enregistré'
                                    });
                                });

                            return;
                        }

                        customer.id = customerId;
                        updateCustomer(customer)
                            .then((res) => res.json())
                            .then((data) => {
                                onCloseCustomer(data.customer.id);
                                notification['success']({
                                    message: 'Le client a bien été mis à jour'
                                });
                            });
                    });
            })
            .catch(() => {
                message.error('champs manquants');
            });
    };

    /**
     *
     * @param {*} customerId
     */
    const onCloseCustomer = (customerId=null) => {
        form.resetFields();
        setLocation(null);

        props.onClose(customerId);
    }

    /**
     *
     */
    const fetchCustomer = () => {
        getOneCustomer(customerId)
            .then((res) => res.json())
            .then((json) => {
                form.setFieldsValue({
                    customer_name: (json.customer.name === '') ? null : json.customer.name,
                    customer_address: json.customer.address,
                    customer_longitude: json.customer.longitude,
                    customer_latitude: json.customer.latitude,
                    customer_firstName: json.customer.firstname,
                    customer_phone: json.customer.phone,
                    customer_mobilePhone: json.customer.mobilePhone,
                    customer_email: json.customer.email,
                    customer_floor: json.customer.floor,
                    customer_intercomCode: json.customer.intercomCode,
                    customer_comment: json.customer.comment,
                    customer_zip_code: json.customer.zip_code
                });

                setLocation({
                    "label": json.customer.address,
                    "value": {}
                });
            });
    };

    /**
     *
     */
    const resetCustomer = () => {
        form.setFieldsValue({
            customer_name: null,
            customer_address: null,
            customer_longitude: null,
            customer_latitude: null,
            customer_firstName: null,
            customer_phone: null,
            customer_mobilePhone: null,
            customer_email: null,
            customer_floor: null,
            customer_intercomCode: null,
            customer_comment: null,
            customer_zip_code: null
        });

        setLocation({
            label: null,
            value: {}
        });
    };

    /**
     *
     */
    useEffect(() => {
        if (action === 'save') {
            resetCustomer();
            setTitleCustomerDrawer('Création d\'un client');
        } else if (customerId && action === 'update') {
            fetchCustomer();
            setTitleCustomerDrawer('Information du client');
        }
    }, [customerId, action, show]);

    /**
     *
     */
    useEffect(() => {
        if (screens.xs || screens.sm) {
            setWidthDrawer(300);
        }

        if (screens.md || screens.lg || screens.xl) {
            setWidthDrawer(600);
        }
    }, [screens]);

    return (
        <Drawer
            title={titleCustomerDrawer}
            width={widthDrawer}
            onClose={() => onCloseCustomer()}
            visible={show}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space size="middle">
                    <Button onClick={() => onCloseCustomer()} style={{ marginRight: 8 }}>
                        Annuler
                    </Button>
                    <Button onClick={onFinish} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Nom"
                    name="customer_name"
                    rules={[{
                        required: true,
                        message: "Merci d\'indiquer un nom."
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Prénom"
                    name="customer_firstName"
                    rules={[{
                        required: true,
                        message: "Merci d\'indiquer un prénom."
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="customer_email">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Adresse"
                    name="customer_address"
                    rules={[{
                        required: true,
                        message: "Merci de renseigner une adresse."
                    }]}
                >
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                        selectProps={{
                            value: location,
                            onChange: onChangeLocation,
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['fr'],
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label="Code Postal" name="customer_zip_code">
                    <Input />
                </Form.Item>
                <Form.Item label="Téléphone" name="customer_phone">
                    <Input />
                </Form.Item>
                <Form.Item label="Téléphone mobile" name="customer_mobilePhone">
                    <Input />
                </Form.Item>
                <Form.Item label="Etage" name="customer_floor">
                    <Input />
                </Form.Item>
                <Form.Item label="Interphone" name="customer_intercomCode">
                    <Input />
                </Form.Item>
                <Form.Item label="Commentaire" name="customer_comment">
                    <TextArea rows={12} />
                </Form.Item>
            </Form>
        </Drawer>
    )
};

export default CustomerForm;