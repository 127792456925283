import config from '../Config.js';

/**
 * 
 * 
 * @returns 
 */
export function getCourseDeliveryOptions(data) {
    return fetch(config.hostApi + 'deliveryoptions', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}

/**
 * 
 * 
 * @returns 
 */
export function getDeliveryOptionsForPrice(data) {
    return fetch(config.hostApi + 'deliveryoptions/list', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
}