import React from 'react';

import { Link } from 'react-router-dom';

import { Button, Divider, Modal, Checkbox, Alert } from 'antd';
import { InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

import CourseForm from './CourseForm.js';
import { GlobalContext } from '../../GlobalContext.js';
import { validateCourse, postCourse } from '../../Api/Courses.js';

class CourseAdd extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            redirection: false,
            idCourse: null,
            course: null,
            customer: null,
            merchant: null,
            driver: null,
            isWarningModalVisible: false,
            errors: [],
            errorsCount: null,
            price: null,
            courseToSave: null,
            validateCourseModalVisible: false,
            disable: true,
            id: null,
            distance: null,
            modalAvailableVisible: false,
            previousPrice: null,
            newPrice: null
        }

        this.onValidate = this.onValidate.bind(this);
        this.handleAvailableCancel = this.handleAvailableCancel.bind(this);
        this.handleAvailableOk = this.handleAvailableOk.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onValidate(values) {
        postCourse(values)
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    Modal.error({
                        title: json.error,
                        content: json.message,
                        onOk() { }
                    });
                    return;
                }

                if (json.errors.length > 0) {
                    let errors = json.errors;
                    const messages = {
                        101: 'Le colis est trop lourd.',
                        102: 'Le colis est trop volumineux.',
                        103: 'La longueur maximale du colis est dépassée.',
                        104: 'La largeur maximale du colis est dépassée.',
                        105: 'La hauteur maximale du colis est dépassée.',
                        201: 'La distance maximale de livraison est dépassée.',
                        202: 'La zone de livraison ne se situe pas dans la zone autorisée.',
                        203: 'La zone de retrait ne se situe pas dans la zone autorisée.',
                        301: 'La date de récupération et de livraison du colis ne sont pas le même jour.',
                        302: 'L\'heure de retrait demandée est dépassée.',
                        303: 'L\'heure de livraison demandée est dépassée.',
                        304: 'L\'heure de livraison est avant l\'heure de retrait.',
                        305: 'Le délais de prévenance n\'est pas respecté.',
                        306: 'L\'heure de retrait autorisée est dépassée.',
                        307: 'L\'heure de retrait ou livraison ne rentre pas dans les horaires d\'ouverture.',
                        308: 'La date de livraison demandée correspond à un jour non travaillé.',
                        401: 'Aucune tarification trouvée pour la course.'
                    }
                    let errorsMessage = [];
                    if (errors.length > 0) {
                        errors.map((error) => {
                            errorsMessage.push(messages[error]);
                        })
                        Modal.warning({
                            title: 'Voici les avertissements sur votre course.',
                            content: (
                                <div>
                                    {errorsMessage.map((error) => {
                                        return (
                                            <Alert showIcon message={error} type="warning" style={{ marginTop: '20px', marginBottom: '20px' }} />
                                        )
                                    })}
                                </div>
                            ),
                            onOk() { }
                        })
                    }
                } else {
                    if (json.course !== null) {
                        if (json.course.merchantPrice !== null) {
                            this.setState({
                                id: json.course.id,
                                price: json.course.merchantPrice,
                                distance: json.course.distance,
                                validateCourseModalVisible: true,
                            });
                        }
                    }
                }
            })
    };

    /**
     * 
     */
    handleOk = () => {
        let id = this.state.id;
        const confirm = {
            confirm: false
        };
        validateCourse(id, confirm)
            .then(res => res.json())
            .then(json => {
                if (json.error === null) {
                    this.setState({
                        idCourse: json.course.short_uuid,
                        redirection: true,
                        validateCourseModalVisible: false,
                    });
                } else {
                    this.setState({
                        modalAvailableVisible: true,
                        previousPrice: json.previousPrice,
                        newPrice: json.newPrice,
                        course: json.course
                    });
                }
            })
    };

    /**
     * 
     */
    handleAvailableOk() {
        const courseId = this.state.course.id;
        const confirm = {
            confirm: true
        };
        validateCourse(courseId, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    this.setState({
                        idCourse: data.course.short_uuid,
                        redirection: true,
                        validateCourseModalVisible: false,
                    });
                } else {
                    this.setState({
                        modalAvailableVisible: true,
                        previousPrice: data.previousPrice,
                        newPrice: data.newPrice
                    })
                }
            })
        this.setState({
            modalAvailableVisible: false
        })
    }

    /**
     * 
     */
    handleAvailableCancel() {
        this.setState({
            modalAvailableVisible: false
        })
    }

    /**
     * 
     */
    handleCancel = () => {
        this.setState({ validateCourseModalVisible: false });
    };

    /**
     * 
     * @param {*} e 
     */
    onChangeCGU = (e) => {
        if (e.target.checked === true) {
            this.setState({ disable: false });
        } else {
            this.setState({ disable: true });
        }
    }

    render() {
        const { redirection } = this.state;
        const { idCourse } = this.state;
        const { validateCourseModalVisible } = this.state;
        let disable = this.state.disable;

        let distance = this.state.distance / 1000;
        let price = this.state.price;

        let courseForm = null;
        let courseSend = null;
        if (redirection === true) {
            courseForm = null;
            courseSend = (
                <div>
                    <h2 className="send-course-end">Félicitations</h2>
                    <p className="send-course-end">Votre demande de course vient d'être transmise !</p>
                    <Divider style={{ color: "#2FAC66", fontSize: '20px', marginTop: '20px' }}>Id de la course</Divider>
                    <p className="send-course-end">{idCourse}</p>
                    <Button type="primary">
                        <Link to={'/courses'}><UnorderedListOutlined /> Gestion des courses</Link>
                    </Button>
                </div>
            )
        } else {
            courseForm = (
                <CourseForm user={this.context.global.profile} formSubmit={this.onValidate} />
            )
        }

        if (price === null) {
            price = 0;
        }

        return (
            <div>
                <div style={{ margin: '16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> NOUVELLE COURSE</h2>
                        <Divider></Divider>
                        {courseForm}
                        {courseSend}
                    </div>
                </div>
                <Modal
                    visible={validateCourseModalVisible}
                    title="Confirmation de course"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button onClick={this.handleCancel}>
                            Retour
                        </Button>,
                        <Button type="primary" onClick={this.handleOk} disabled={disable}>
                            Lancer La course
                        </Button>,
                    ]}
                >
                    <Divider orientation="left">Détails</Divider>
                    <p>Distance : {distance} km </p>
                    <p>Prix H.T. : {price} €</p>
                    <Divider orientation="left">Confirmation</Divider>
                    <Checkbox onChange={this.onChangeCGU}>J'ai lu et j'accepte les <a href='https://applicolis.com/cgu/'> conditions générales d'utilisations </a> d'Applicolis</Checkbox>
                </Modal>
                <Modal visible={this.state.modalAvailableVisible} onOk={this.handleAvailableOk} onCancel={this.handleAvailableCancel} cancelButtonProps={{ style: { display: 'none' } }}>
                    <Alert
                        showIcon
                        message="Le prix de la course a changé."
                        description={
                            <div>
                                <p>Ancien prix : {this.state.previousPrice} €</p>
                                <p>Nouveau prix : {this.state.newPrice} €</p>
                                <p>Confirmez-vous l'émission de la course avec le nouveau prix ?</p>
                            </div>
                        }
                        type="warning"
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                    />
                </Modal>
            </div>
        )
    }
}

export default CourseAdd;