import React, { useEffect } from 'react';
import {
    Space,
    Button,
    Form,
    Input,
    InputNumber,
    Drawer,
} from 'antd';

const { TextArea } = Input;

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputNumberCustom = (props) => (
    <InputNumber
        style={{ width: '100%' }}
        placeholder="100"
        prefix="€"
        min={0}
        precision={2}
        formatter={value => value.replace('.', ',')}
        parser={value => value.replace(',', '.')}
        {...props}
    />
);

/**
 *
 * @param {*} props
 * @returns
 */
const PacketForm = (props) => {
    const { packet, visible } = props;

    const [form] = Form.useForm();

    /**
     *
     */
    const onSubmit = () => {
        let values = form.getFieldsValue();
        props.onSubmit(values);
    };

    /**
     *
     */
    const onChangePacketSize = () => {
        let values = form.getFieldsValue();
        const { packet_length=null, packet_width=null , packet_height=null } = values;

        if (!packet_length || !packet_width || !packet_height) {
            return;
        }

        let newVolume = (packet_length * packet_width * packet_height) / 1000;
        form.setFieldsValue({ packet_volume: newVolume });
    };

    /**
     *
     */
    useEffect(() => {
        let defaultValues = {
            packet_description: null,
            packet_price: null,
            packet_weight: null,
            packet_length: null,
            packet_width: null,
            packet_height: null,
            packet_volume: null,
            packet_comment: null,
        };

        if (packet) {
            defaultValues.packet_description = packet.packet_description;
            defaultValues.packet_price = packet.packet_price
            defaultValues.packet_weight = packet.packet_weight;
            defaultValues.packet_length = packet.packet_length;
            defaultValues.packet_width = packet.packet_width;
            defaultValues.packet_height = packet.packet_height;
            defaultValues.packet_volume = packet.packet_volume;
            defaultValues.packet_comment = packet.packet_comment;
        }

        form.setFieldsValue(defaultValues);
    }, [packet]);

    const labelVlomueTooltip =
        'Le volume peut-être renseigné manuellement ou auto-calculé avec les dimensions du colis saisies';

    return (
        <Drawer
            title="Paramètres du colis"
            width={720}
            visible={visible}
            onClose={props.onClose}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space size="middle">
                    <Button onClick={props.onClose}>
                        Annuler
                    </Button>
                    <Button onClick={onSubmit} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form form={form} layout="vertical">
                <Form.Item label="Description" name="packet_description">
                    <TextArea
                        rows={3}
                        placeholder={"Vous pouvez préciser ici les dimensions (largeur, hauteur, profondeur), " +
                            "le poids, la nature ou sa fragilité..."}
                    />
                </Form.Item>
                <Form.Item label="Prix H.T." name="packet_price">
                    <InputNumberCustom placeholder="100" prefix="€" />
                </Form.Item>
                <Form.Item label="Poids" name="packet_weight" required>
                    <InputNumberCustom placeholder="10" prefix="kg" />
                </Form.Item>
                <Form.Item label="Longueur" name="packet_length" required>
                    <InputNumberCustom placeholder="10" prefix="cm" onChange={onChangePacketSize} />
                </Form.Item>
                <Form.Item label="Largeur" name="packet_width" required>
                    <InputNumberCustom placeholder="10" prefix="cm" onChange={onChangePacketSize} />
                </Form.Item>
                <Form.Item label="Hauteur" name="packet_height" required>
                    <InputNumberCustom placeholder="10" prefix="cm" onChange={onChangePacketSize} />
                </Form.Item>
                <Form.Item
                    label="Volume"
                    name="packet_volume"
                    tooltip={labelVlomueTooltip}
                    required
                >
                    <InputNumberCustom placeholder="10" prefix="dm³" />
                </Form.Item>
                <Form.Item label="Commentaire produit" name="packet_comment">
                    <TextArea name="packet_comment" rows={6} />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default PacketForm;