import React from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";

import { Layout, Menu, Avatar, Button, ConfigProvider, Row, Col } from "antd";
import {
    UnorderedListOutlined, UserOutlined, PlusCircleOutlined, TeamOutlined,
    ShoppingCartOutlined, InfoCircleOutlined, PieChartOutlined, ShopOutlined
} from "@ant-design/icons";
import Icon from "@ant-design/icons";

import { ReactComponent as IconLogo } from "./map.svg";

import logoLong from "./logo-enlong.png";
import logoCourt from "./logo-court.png";
import { getUser } from "./Api/Login.js";
import { GlobalContext } from "./GlobalContext";

import moment from "moment";
import "moment/locale/fr";
import frFR from "antd/lib/locale/fr_FR";
import { getOneNetwork } from "./Api/Networks";
import { getOneWhiteLabel } from "./Api/WhiteLabels";

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

moment.locale("fr");

class AppLayout extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            user: null,
            openKeys: [""],
            redirection: false,
            hover: false,
            courseRedirection: false,
            courseId: null,
            locale: frFR,
            color: "#2FAC66",
            logo: logoLong,
            networkId: null,
            whiteLabelId: null,
            logoCourt: logoCourt,
            phone: "05.31.60.68.77"
        };

        this.onCollapse = this.onCollapse.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
        this.onClickDeconnexion = this.onClickDeconnexion.bind(this);
        this.toggleHover = this.toggleHover.bind(this);
    }

    /**
     *
     */
    async loadUser() {
        await getUser()
            .then((res) => res.json())
            .then((data) => {
                let networkId = null;
                if (data.user.merchant) {
                    networkId = data.user.merchant.network_id;
                }
                this.setState({
                    user: data.user,
                    whiteLabelId: data.user.whiteLabel_id,
                    networkId: networkId
                });
                this.context.updateGlobal({
                    ...this.context.global,
                    profile: data.user,

                });
            });
    }

    /**
     * 
     */
    async loadTemplate() {
        if (this.state.whiteLabelId !== null && this.state.networkId !== null) {
            Promise.all([
                getOneNetwork(this.state.networkId).then(value => value.json()),
                getOneWhiteLabel(this.state.whiteLabelId).then(value => value.json())
            ]).then(allResponses => {
                let network = allResponses[0];
                let whiteLabel = allResponses[1];

                if (whiteLabel.whiteLabel.color !== null) {
                    if (network.network.color !== null) {
                        this.setState({ color: network.network.color })
                    } else {
                        this.setState({ color: whiteLabel.whiteLabel.color })
                    }
                } else {
                    if (network.color !== null) {
                        this.setState({ color: network.network.color })
                    }
                }

                if (whiteLabel.whiteLabel.logo !== null) {
                    if (network.network.logo !== null) {
                        this.setState({
                            logo: network.network.logo,
                            logoCourt: network.network.logo
                        })
                    } else {
                        this.setState({
                            logo: whiteLabel.whiteLabel.logo,
                            logoCourt: whiteLabel.whiteLabel.logo
                        })
                    }
                } else {
                    if (network.network.logo !== null) {
                        this.setState({
                            logo: network.network.logo,
                            logoCourt: network.network.logo
                        })
                    }
                }

                if (whiteLabel.whiteLabel.phone !== null) {
                    if (network.network.phone !== null) {
                        this.setState({
                            phone: network.network.phone
                        })
                    } else {
                        this.setState({
                            phone: whiteLabel.whiteLabel.phone
                        })
                    }
                } else {
                    if (network.network.phone !== null) {
                        this.setState({
                            phone: network.network.phone
                        })
                    }
                }
            })
        } else if (this.state.whiteLabelId === null && this.state.networkId !== null) {
            getOneNetwork(this.state.networkId)
                .then(value => value.json())
                .then(data => {
                    if (data.network.color !== null) {
                        this.setState({ color: data.network.color });
                    }
                    if (data.network.logo !== null) {
                        this.setState({
                            logo: data.network.logo,
                            logoCourt: data.network.logo
                        })
                    }
                    if (data.network.phone !== null) {
                        this.setState({ phone: data.network.phone });
                    }
                })
        }
    }

    /**
     *
     */
    async componentDidMount() {
        await this.loadUser();
        await this.loadTemplate();
    }

    /**
     *
     * @param {*} collapsed
     */
    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    };

    /**
     *
     * @param {*} keys
     */
    onOpenChange = (keys) => {
        if (window.innerWidth > 768) {
            if (!this.state.collapsed) {
                let rootSubmenuKeys = [
                    "sub1",
                    "sub2",
                ];
                const latestOpenKey = keys.find(
                    (key) => this.state.openKeys.indexOf(key) === -1
                );
                if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.setState({ openkeys: keys });
                } else {
                    this.setState({
                        openKeys: latestOpenKey ? [latestOpenKey] : [],
                    });
                }
            }
        }
    };

    /**
     *
     */
    onClickDeconnexion() {
        localStorage.clear();
        this.setState({ redirection: true });
    }

    /**
     *
     */
    toggleHover() {
        this.setState({ hover: !this.state.hover });
    }

    render() {
        const { collapsed } = this.state;
        const { children } = this.props;
        const { location } = this.props;
        const { redirection } = this.state;

        let logo = this.state.logo;

        if (redirection) {
            return <Redirect to="/login" />;
        }

        if (collapsed === true) {
            logo = this.state.logoCourt;
        }

        let name = null;

        if (this.state.user !== null) {
            name = this.state.user.name;
        }

        let buttonHover = null;
        if (this.state.hover) {
            buttonHover = <div className="logoutButton">Deconnexion</div>;
        }

        const border = {
            borderBottom: "solid 2px" + this.state.color
        }

        return (
            <ConfigProvider locale={this.state.locale}>
                <Layout style={{ minHeight: "100vh" }}>
                    <Sider
                        breakpoint="lg"
                        collapsedWidth="30px"
                        width="250px"
                        theme="light"
                        collapsible
                        collapsed={collapsed}
                        onBreakpoint={() => {
                            this.setState({ collapsed: true })
                        }}
                        onCollapse={this.onCollapse}
                    >
                        <div className="logo">
                            <img src={logo} className="logoImg" alt="logo" />
                        </div>
                        <Menu
                            defaultSelectedKeys={[location.pathname]}
                            mode="inline"
                        >
                            <Menu.Item key="/" icon={<PieChartOutlined />}>
                                <Link to="/">DashBoard</Link>
                            </Menu.Item>
                            <Menu.Item
                                key="/map"
                                icon={<Icon component={IconLogo} style={{ fontSize: 15 }} />}
                            >
                                <Link to="/map">Carte</Link>
                            </Menu.Item>
                            <Menu.Item key="/courses">
                                <Link to="/courses">
                                    <UnorderedListOutlined style={{ marginRight: 5 }}/> Toutes
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/course/add">
                                <Link to="/course/add">
                                    <PlusCircleOutlined style={{ marginRight: 5 }}/> Nouvelle Course
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/customers">
                                <Link to="/customers">
                                    <TeamOutlined style={{ marginRight: 5 }} /> Clients
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/stores" icon={<ShopOutlined />} >
                                <Link to="/stores">Entrepôts</Link>
                            </Menu.Item>
                            <Menu.Item key="/profile" icon={<UserOutlined />} >
                                <Link to="/profile">Profil</Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: "0", borderBottom: border.borderBottom }}>
                            <div>
                                <div className='phone'><InfoCircleOutlined style={{ color: this.state.color }} /> {this.state.phone}</div>
                                <div className="avatar">
                                    <Button
                                        type="text"
                                        onClick={this.onClickDeconnexion}
                                        onMouseEnter={this.toggleHover}
                                        onMouseLeave={this.toggleHover}
                                    >
                                        Bienvenue {name}
                                        {buttonHover}
                                    </Button>
                                    <Avatar style={{ backgroundColor: this.state.color }} icon={<UserOutlined />} />
                                </div>
                            </div>
                        </Header>
                        {children}
                        <Footer className="site-layout-background" style={{ textAlign: "center" }}>
                            <Row gutter={[24, 48]}>
                                <Col span={8}>
                                    <div className='footer-text'>
                                        <a href='https://applicolis.com/qui-sommes-nous/'>A propos de nous</a>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='footer-text'>
                                        <a href='https://applicolis.com/mentions-legales/'>Mentions Légales</a>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='footer-text'>
                                        <a href='https://applicolis.com/cgu/'>CGU</a>
                                    </div></Col>
                            </Row>
                            <Row gutter={[24, 48]}>
                                <Col span={24}>
                                    <div className='footer-text'>
                                        Logiciel de livraison développé par
                                        <a href='https://applicolis.com'>
                                            <img className="logo-footer" src={logoLong} />
                                        </a>
                                    </div>
                                </Col>

                            </Row>
                        </Footer>
                    </Layout>
                </Layout>
            </ConfigProvider>
        );
    }
}

export default withRouter(AppLayout);